<template>
  <div class="alert">
    <div class="container_basic middle">
      <header class="top2">
        <span class="header_tit2">免费注册</span>
      </header>
      <div class="cont2">
        <input
          type="text"
          class="tel_num"
          placeholder="请输入手机号"
          v-model="telnum"
        />
        <span class="error_tel" v-show="tel_error"
          ><span class="error_icon"></span>{{ tel_error }}!</span
        >
        <div class="testcode">
          <input
            type="text"
            class="test_num"
            placeholder="输入验证码"
            v-model="code"
          />
          <button class="test_txt" @click="fnVerify(0)" v-if="isGetcode">
            获取验证码
          </button>
          <button
            class="test_txt"
            v-else
            @click="fnVerify(1)"
            :disabled="isSend"
          >
            <span v-show="iscount">({{ count }}s)</span>重新发送
          </button>
        </div>
        <span class="error error_test" v-show="code_err"
          ><span class="error_icon"></span>{{ code_err }}！</span
        >
        <input
          type="password"
          class="pwd_num"
          placeholder="请设置登录密码"
          v-model="pwdnum"
        />
        <input
          type="password"
          class="pwd_num again"
          placeholder="再次确认密码"
          v-model="ensurepwd"
        />
        <span class="warn_txt">
          <i :class="['warn_icon', { act: isArgee }]" @click="agreePrivacy"></i>
          继续表示您同意
          <span @click.stop="goXieyi">马拉松用户协议</span>和
          <span @click.stop="goPrivacy">隐私政策</span>
        </span>
        <span class="error" v-show="pwdnum_error"
          ><span class="error_icon"></span>{{ pwdnum_error }}!</span
        >
        <button class="login" @click="reg_submit">注册</button>
        <div class="noLogin clearfix">
          <button
            class="check f_left"
            v-if="isChecked"
            @click="chooseNologin"
          ></button>
          <button class="check check_act f_left" v-else @click="chooseNologin">
            ✔
          </button>
          <span class="f_left">十天免登录</span>
          <span class="forget f_right">
            已有账号，
            <span @click="openLogin">直接登录</span>
          </span>
        </div>
      </div>
      <div class="close_icon" @click="closeAlert"></div>
    </div>
    <!-- 人机验证 -->
    <Verify
      @success="fnVerifySuccess"
      :mode="'pop'"
      :captchaType="'blockPuzzle'"
      :imgSize="{ width: '330px', height: '155px' }"
      ref="verify"
    ></Verify>
  </div>
</template>

<script>
import { checkPhone, checkPwd } from "../../config/utils";
import { basic } from "../../config/request";
import Verify from "@/components/verifition/Verify";
export default {
  name: "Register",
  inject: ["reload"],
  components: {
    Verify,
  },
  data() {
    return {
      telnum: "", //手机号
      tel_error: "", //手机号错误信息
      code: "", //验证码
      code_key: "",
      code_err: "", //验证码错误信息
      pwdnum: "", //密码
      ensurepwd: "", //确认密码
      pwdnum_error: "", //密码错误信息
      count: 60,
      isChecked: true, //是否十天免登陆
      isGetcode: true, //默认显示获取验证码
      iscount: false, //一分钟倒计时是否显示
      isSend: false, //是否可以点击重新发送
      isArgee: false,
    };
  },
  methods: {
    // 人机验证成功
    fnVerifySuccess(params) {
      if (this.getCodeNum === 0) {
        this.isGetcode = false;
      } else {
        // 重新发送
        this.isSend = true;
        this.iscount = true;
      }
      this.tel_error = "";
      this.coutTime();
      this.getCode(params.captchaVerification);
    },
    // 人机验证
    fnVerify(num = 0) {
      // 校验手机号格式
      if (checkPhone(this.telnum)) {
        this.getCodeNum = num;
        this.$refs.verify.show();
      } else {
        this.tel_error = "请检查您的手机号";
      }
    },
    // 获取验证码
    getCode(captchaVerification = "") {
      basic
        .code2({
          phone: this.telnum,
          captchaVerification,
        })
        .then((res) => {
          // console.log(res)
          if (res.code == 1) {
            console.log("获取二维码成功");
            this.code_key = res.data.key;
          }
        });
    },
    // 一分钟倒计时
    coutTime() {
      this.iscount = true; //显示倒计时
      this.isSend = true; //倒计时之前不可点击重新发送
      this.count--;
      if (this.count == 0) {
        this.iscount = false; //隐藏倒计时
        this.isSend = false; //可以重新发送
        this.count = 60;
        return;
      }
      setTimeout(() => {
        this.coutTime();
      }, 1000);
    },
    // 关闭弹窗
    closeAlert() {
      (this.telnum = ""), //手机号
        (this.tel_error = ""), //手机号错误信息
        (this.code = ""), //验证码
        (this.code_err = ""), //验证码错误信息
        (this.pwdnum = ""), //密码
        (this.ensurepwd = ""), //确认密码
        (this.pwdnum_error = ""), //密码错误信息
        this.$emit("closeReg", false);
    },
    //是否选择十天免登陆
    chooseNologin() {
      this.isChecked = !this.isChecked;
    },
    // 打开登录弹框
    openLogin() {
      this.$emit("openLogin", true);
    },
    // 点击注册
    reg_submit() {
      this.tel_error = "";
      this.pwdnum_error = "";
      if (!this.telnum) {
        this.pwdnum_error = "请输入手机号";
        return;
      }
      if (!this.code_key) {
        this.pwdnum_error = "请获取验证码";
        return;
      }
      if (!checkPwd(this.pwdnum)) {
        this.pwdnum_error = "请输入6-16位字母和数字组合的密码";
        return;
      }
      if (this.pwdnum !== this.ensurepwd) {
        this.pwdnum_error = "2次输入的密码不一致，请重新修改";
        return;
      }
      if (!this.isArgee) {
        this.pwdnum_error = "请先勾选同意后再进行注册";
        return;
      }

      this.pwdnum_error = "";
      let password = this.encryptString(this.pwdnum);
      basic
        .reg({
          phone: this.telnum,
          password: password,
          key: this.code_key,
          code: this.code,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            this.code_err = "";
            let mdr = this.isChecked ? 2 : 1;
            basic
              .log({
                phone: this.telnum,
                password: password,
                mdr: mdr,
                base: 1,
              })
              .then((res2) => {
                if (res2.code == 1) {
                  // 获取token并存储
                  let access_token = res2.data.tokenInfo.access_token;
                  sessionStorage.setItem("access_token", access_token);
                  // 获取用户信息
                  this.$emit("setInfo", res2.data.user);
                  this.closeAlert();
                  this.reload();
                }
              });
          } else if (res.code == 409) {
            this.code_err = res.message;
          } else if (res.code == 410) {
            this.code_err = "";
            this.pwdnum_error = res.message;
          } else if (res.code == 0) {
            this.pwdnum_error = res.message;
          }
        });
    },
    // 查看协议
    goXieyi() {
      this.$router.push({
        path: "/xieyi",
      });
    },
    // 查看隐私政策
    goPrivacy() {
      this.$router.push({
        path: "/privacy",
      });
    },
    // 统一或取消协议
    agreePrivacy() {
      this.isArgee = !this.isArgee;
    },
  },
  created() {
    this.isArgee = false;
  },
  mounted() {},
};
</script>

<style scoped>
@import "../../assets/alertPublic/public.css";
@import "./style/register.css";
</style>
