<template>
    <div class="alert download_cont" v-if="isDownloadshow">
        <div class="cont middle">
            <header class="container_top">下载</header>
            <vue-qr :logoSrc="img" :text="url" :size="224" :margin="0" :dotScale = '1'></vue-qr>
            <span class="close_icon" @click="closeAlert"></span>
            <span class="container_bot">扫一扫下载马拉松报名手机APP <span>安装手机版后可享受专业赛事服务哦</span></span>
         </div>
    </div>
</template>

<script>
import vueQr from 'vue-qr'
export default {
    name:'Download',
    components:{
        vueQr
    },
    props:{
        isDownloadshow: {
            type:Boolean
        },
        url:{
            type:String
        }
    },
    watch: {
        isDownloadshow(newValue, oldValue) {
    // 　　　　console.log(newValue)
            this.isDownloadshow = newValue;
    　　},
        url(newValue, oldValue) {
            this.url = newValue;
    　　}
    },
    data() {
        return {
            img:require('../../assets/public/favicon.png')
        }
    },
    methods: {
        closeAlert() {
            this.$emit('closeAlert',false)
        }
    }
}
</script>

<style scoped>
@import './style/download.css';
</style>
