<template>
    <div class="dialog_box">
        <el-dialog :title="dialogTitle" :visible.sync="isShowDialog" center width="66%" :show="show" @close="closeDialog">
            <el-form ref="formData" :model="formData" :rules="formRules" label-width="200px">
                <el-form-item
                  :label="item.label"
                  :prop="item.key"
                  v-for="(item, index) in formControl"
                  :key="index"
                  :class="{'upload_formItem': item.type == 'image' || item.type == 'file'}"
                >
                    <!-- input 类型 -->
                    <el-input v-if="item.type == 'text'" v-model="formData[item.key]" :placeholder="item.placeholder"></el-input>
                    <!-- radio 类型 -->
                    <el-radio-group v-if="item.type == 'radio'" v-model="formData[item.key]">
                        <el-radio
                          :label="radioItem.value"
                          v-for="(radioItem, radioIndex) in item.options"
                          :key="radioIndex"
                        >
                            {{radioItem.label}}
                        </el-radio>
                    </el-radio-group>
                    <!-- select 类型 -->
                    <!-- 生日的select -->
                    <el-date-picker
                      v-if="item.type == 'select' && item.key == 'birthday'"
                      v-model="formData[item.key]"
                      type="date"
                      :placeholder="item.placeholder"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                    <!-- 地区的select -->
                    <el-cascader
                      v-if="item.type == 'select' && item.key == 'location'"
                      v-model="formData[item.key]"
                      :options="options"
                    ></el-cascader>
                    <!-- 基本的select -->
                    <el-select
                      v-if="item.type == 'select' && item.options.length > 1"
                      v-model="formData[item.key]"
                      :placeholder="item.placeholder"
                    >
                        <el-option
                          v-for="(selectItem, selectIndex) in item.options"
                          :key="selectIndex"
                          :label="selectItem.label"
                          :value="selectItem.value">
                        </el-option>
                    </el-select>
                    
                    <!-- 上传图片类型 -->
                    <el-upload
                      v-if="item.type == 'image' || item.type == 'file'"
                      :action="action"
                      name="upfile"
                      multiple
                      list-type="picture-card"
                      :on-success="uploadSuccess"
                      :limit="9"
                      :file-list="fileObj[item.key]"
                    >   
                        <i class="el-icon-plus" @click="uploadImageBtn(item.key)"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item
                  :label="otherItem.name"
                  class="upload_formItem"
                  v-for="(otherItem, otherIndex) in otherFormData"
                  :key="`${otherIndex}_0`"
                >
                    <el-cascader
                      v-if="otherFormData.length > 0"
                      :options="otherItem.options"
                      v-model="formData[otherItem.id]"
                      @change="handleChangeData"
                      @focus="handleFocus(otherItem)"
                    ></el-cascader>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="submitForm">保存</el-button>
            </span>
        </el-dialog>
        <div class="tips" v-show="isShowTips">{{tipsText}}</div>
    </div>
</template>
<script>
import { personalCenter, uploadImage } from '@/config/request'
import { getBirthDay, getSex } from '@/config/utils'
import provinceObj from './area'
import VDistpicker from 'v-distpicker';
export default {
    name: 'info-dialog',
    inject:['reload'],
    components: {
        VDistpicker,
    },
    props: {
        show: {
           type: Boolean,
            default: false 
        },
        formControl: { // 动态表单控件
            type: Array,
            default: () => {
                return []
            }
        },
        formRules: { // 表单规则
            type: Object,
            default: () => {
                return {}
            }
        },
        otherFormData: {
            type: Array,
            default: () => {
                return []
            }
        },
        updateFormData: { // 修改信息报名人信息
            type: Object,
            default: () => {
                return {}
            }
        },
        entryId: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            action: uploadImage,
            isShowDialog: this.show, // 是否显示弹框
            formData: {}, // 表单数据
            dialogTitle: '新增报名人信息', // 弹框的标题
            mobile: '', // 用户手机号
            // city: '', // 初始化地区选择
            // location: '', // 地区的值
            tipsText: '', // 提示文案
            isShowTips: false, // 是否显示提示文案
            uploadFileKey: '',
            fileObj: {},
            options: provinceObj,
            formOtherList: [], // 级联表单数据
            formOtherObj: {}, // 单个级联表单字段
            otherForm: {}, // 级联表单数据绑定
        }
    },
    watch: {
        // 点击编辑时获取信息
        updateFormData(newValue) {
            this.formControl.forEach(item => {
                let imageArr = []
                if(item.type == "image" || item.type == "file") {
                    if(newValue.hasOwnProperty(item.key)) {
                        // 文件图片回显的处理
                        let value = newValue[item.key]
                        value = value.split(',')
                        // this.imageResult = value
                        // console.log(value)
                        value.forEach(subItem => {
                            imageArr.push({
                                name: Math.random() + '.png',
                                url: subItem
                            })
                        })
                        this.fileObj[item.key] = imageArr
                    }
                } else if(item.key == 'location') {
                    // let locationData = newValue.location
                    if(newValue.hasOwnProperty(item.key)) {
                        if(typeof newValue[item.key] == 'string') {
                            this.formData[item.key] = newValue.location.split(',').slice(0, 3)
                        } else {
                            this.formData[item.key] = newValue.location
                        }
                    } else {
                        this.formData[item.key] = []
                    }
                }
            })
            if(newValue.hasOwnProperty('selectValus')) {
                newValue["selectValus"].forEach(otherItem => {
                    let otherArr = []
                    let first = otherItem.firstGrade.split('_')[1]
                    let second = otherItem.secondGrade.split('_')[1]
                    otherArr.push(first, second)
                    this.$set(this.formData, otherItem.formSelectId, otherArr)
                })
            }
            this.formData = newValue
            // 编辑时需要多传一个personId参数
            this.$set(this.formData, 'personId', newValue.id)
            this.dialogTitle = '编辑报名人信息'
        },
        show() {
            this.isShowDialog = this.show
        },
        otherFormData(newValue) {
            this.handleOtherFormData(newValue)
        }
    },
    methods: {
        handleChangeData(value) {
            // this.$forceUpdate();
            // this.$set(this.formOtherObj, "firstValue", value[0])
            // this.$set(this.formOtherObj, "secondValue", value[1])
            // this.formOtherList.forEach((item, index) => {
            //     if(item.id == this.formOtherObj.id) {
            //         this.formOtherList.splice(index, 1)
            //     }
            // })
            // this.formOtherList.push(this.formOtherObj)
            // this.$set(this.formData, this.formOtherObj.id, value)
            // this.$set(this.formData, "params", JSON.stringify(this.formOtherList))
            // this.$set(this.formData, "isSelect", true)
        },
        handleFocus(item) {
            // let newObj = {}
            // newObj.id = item.id
            // newObj.firstValue = ""
            // newObj.firstLabel = item.name
            // newObj.secondLabel = item.selectName
            // newObj.secondValue = ""
            // this.formOtherObj = newObj
            this.formOtherList.forEach((otherItem, index) => {
                if(item.id == otherItem.id) {
                    this.formOtherList.splice(index, 1)
                }
            })
            this.formOtherList.push(item)
            console.log(this.formOtherList)
        },
        // 处理额外的表单数据
        handleOtherFormData(newValue) {
            if(newValue.length > 0) {
                newValue.forEach(item => {
                    let itemArr = JSON.parse(item.params)
                    let options = []
                    itemArr.forEach(subItem => {
                        let itemObj = {
                            value: subItem.p,
                            label: subItem.p,
                            children: []
                        }
                        subItem["c"].forEach(childItem => {
                            itemObj.children.push({
                                value: childItem,
                                label: childItem,
                            })
                        })
                        options.push(itemObj)
                    })
                    item.options = options
                    // this.otherForm[item.id] = []
                    // console.log(this.otherForm)
                })
            }
        },
        // 显示提示文案
        showTips(text) {
            this.tipsText = text
            this.isShowTips = true
            setTimeout(() => {
                this.isShowTips = false
            }, 1500)
        },
        // 提交表单数据
        submitForm() {
            let dynamicForm = [] // 是否存在动态字段数据
            let fileKey = []
            // console.log(this.location)
            this.formControl.forEach(item => {
                // 对所在地数据的处理
                if(item.key == 'location') {
                    this.formRules['location'][0]['validator'] = (rule, value, callback) => {
                        if(value.length > 0) {
                            this.$set(this.formData, item.key, value.join(','))
                            callback();
                        } else {
                            callback(new Error('请选择' + item.label));
                        }
                            
                    }
                }
                // 对证件号的处理  选择的类型为身份证，则进行校验
                if(item.key == 'cardId') {
                    if(this.formData.cardType == '身份证') { // 选择了身份证
                        this.formRules['cardId'][0]['validator'] = (rule, value, callback) => {
                            let numExp = /(^\d{15}$)|(^\d{17}([0-9]|X|x)$)/
                            if (value) {
                                if (!numExp.test(value)) {
                                    callback(new Error('请输入正确的证件号'))
                                } else {
                                    // 输入身份证之后自动填充性别和生日
                                    this.$set(this.formData, 'sex', getSex(this.formData['cardId']))
                                    this.$set(this.formData, 'birthday', getBirthDay(this.formData['cardId']))
                                    callback()
                                }
                            } else {
                                callback(new Error('请输入证件号'))
                            }
                        }
                    } else { // 选择了护照，不做校验
                        this.formRules['cardId'][0]['validator'] = (rule, value, callback) => {
                            if (value) {
                                callback()
                            } else {
                                callback(new Error('请输入证件号'))
                            }
                        }
                    }
                }
                // 对文件图片上传的校验处理
                if(item.type == 'image' || item.type == 'file') {
                    this.formRules[item.key][0]['validator'] = (rule, value, callback) => {
                        if(this.fileObj[item.key] && this.fileObj[item.key].length > 0) {
                            let files = []
                            this.fileObj[item.key].forEach(file => {
                                if(file.hasOwnProperty('response')) {
                                    files.push(file.response.openUrl)
                                } else {
                                    files.push(file.url)
                                }
                            })
                            this.$set(this.formData, item.key, files.join(','))
                            callback()
                        } else {
                            callback(new Error('请上传' + item.label))
                        }
                    }
                    
                }
                // 对动态字段数据的处理
                if(item.dynamic) { // 是动态
                    if(item.type != 'image' && item.type != 'file') {
                        dynamicForm.push({
                            column: item.key,
                            id: item.id,
                            value: this.formData[item.key]
                        })
                    } else {
                        let newFile = []
                        this.fileObj[item.key].forEach(file => {
                            if(file.hasOwnProperty('response')) {
                                newFile.push(file.response.openUrl)
                            } else {
                                newFile.push(file.url)
                            }
                        })
                        dynamicForm.push({
                            column: item.key,
                            id: item.id,
                            value: newFile.join(',')
                        })
                    }
                }
            })
            if(this.formData.hasOwnProperty("selectValus")) {
                delete this.formData.selectValus
            }
            this.$set(this.formData, 'dynamicForm', JSON.stringify(dynamicForm))

            if(this.formOtherList.length > 0) {
                let params = []
                this.formOtherList.forEach(otherItem => {
                    if(this.formData.hasOwnProperty(otherItem.id)) {
                        let obj = {
                            id: otherItem.id,
                            firstValue: this.formData[otherItem.id][0],
                            firstLabel: otherItem.name,
                            secondLabel: otherItem.selectName,
                            secondValue: this.formData[otherItem.id][1]
                        }
                        params.push(obj)
                    }
                })
                console.log(params)
                this.$set(this.formData, "params", JSON.stringify(params))
                this.$set(this.formData, "isSelect", true)
            }

            this.$refs['formData'].validate((valid) => {
                console.log(this.formData)
                if (valid) {
                    console.log('submit!');
                    let params = {
                        mobile: this.mobile,
                        entryId: this.entryId,
                        ...this.formData,
                    }
                    personalCenter.saveUserInfo(params).then(res => {
                        if(res.code == 1) {
                            this.showTips('保存成功！')
                            this.resetData()
                            this.closeDialog()
                            this.reload()
                        } else if(res.code == 902 ){
                            this.showTips('该证件号已存在联系人信息！')
                        } else {
                            this.showTips(res.msg)
                        }
                    })
                } else {
                    this.showTips('请填写正确的信息')
                    console.log('error submit!');
                    return false;
                }
            });
        },
        uploadImageBtn(formKey) {
            this.uploadFileKey = formKey
        },
        // 移除文件
        // handleRemove(file, fileList) {
        //     this.$set(this.fileObj, this.uploadFileKey, fileList)
        // },
        // 文件上传成功
        uploadSuccess(response, file, fileList) {
            this.$set(this.fileObj, this.uploadFileKey, fileList)
            // console.log(this.fileObj)
        },
        // 重置数据
        resetData() {
            sessionStorage.removeItem("mobileNum");
            sessionStorage.removeItem("emergencyPhone");
            sessionStorage.removeItem("name");
            sessionStorage.removeItem("emergencyContact");
            // this.fileList = []
            // this.imageResult = []
            this.formData.location = ''
            this.$refs['formData'].resetFields();
            this.fileObj = {}
        },
        // 关闭弹窗
        closeDialog() {
            this.isShowTips = false
            this.resetData()
            this.$emit('closeDialog')
        }
    },
    created() {
        let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
        // this.mobile = '17855864755'
        this.mobile = userInfo ? userInfo.mobile : ''
        // console.log(provinceObj)
        // this.handleOtherFormData()
    }
}
</script>
<style scoped>
    @import './style/infoDialog.css';
</style>

