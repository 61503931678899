import Vue from 'vue'
import App from './App.vue'
import 'babel-polyfill'
import router from './router/router'
import store from './store/index'
import FastClick from 'fastclick'
import VueLazyload from 'vue-lazyload'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Register from './components/register'
import VueResource from 'vue-resource'
import { encryptString, decodeString } from './plugins/encode'
import {showHtml,DateFormatimg,getimg } from './plugins/requestId'
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)
Vue.use(VueResource)
Vue.use(Element)
Vue.use(Register)
Vue.prototype.encryptString = encryptString
Vue.prototype.decodeString = decodeString
//处理数组对象字符图片后缀拼接
Vue.prototype.$DateFormatimg = function(data) {
	console.log(data)
	return DateFormatimg(data)
}
//处理页面图片
Vue.filter('$DateFormatimg', function(dateStr) {
	// console.log(dateStr,'*****************************页面过滤器--------------')
	return getimg(dateStr)
});
//书里富文本里的图片
Vue.prototype.$showHtml = function(url){
	return showHtml(url)
  }
Vue.prototype.bus = new Vue()
FastClick.attach(document.body);
Vue.config.productionTip = false
// Vue.use(VueLazyload, {
//   preLoad: 1.3,
//   error: 'dist/error.png',
//   loading: 'dist/loading.gif',
//   attempt: 1
// })

new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')