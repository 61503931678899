import {
    get,
    post,
    post2,
    put,
    baseUrl,
    equipUrl
} from './http';

// 隐私政策
export const privacyPolicy = 'https://marathon-image.hpaopao.com/privacy/mls_yinsi.html';

// 赛事日历接口
const ProblemFeedback = {
   // 获取问题反馈列表
    //  queryList: params => {
    //     return get(baseUrl + '/api-smc/ui-nl/v1/board/feedback', params)
    // },
    queryList: params => {
        return get(baseUrl + '/api-smc/ui-nl/v1/board/feedback-reply', params)
    },
    // 获取统计详情
    queryCount : params => {
        return get(baseUrl + '/api-smc/ui-nl/v1/board/count', params)
    },
     // 获取任务列表
     queryJob : params => {
        return get(baseUrl + '/api-smc/ui-nl/v1/board/job', params)
    },
}
// 轮播图接口
const banner = query => {
    return get(baseUrl + '/api-smc/ui-m-nl/v1/index/banners', query)
}
//赛事
const event = {
    //赛事列表
    lists: query => {
        return get(baseUrl + '/api-smc/ui-m-nl/v1/mh/ams', query)
    },
    // 推荐赛事列表
    recommends: query => {
        return get(baseUrl + '/api-smc/ui-m-nl/v1/index/hm', query)
    },
    //关注
}
//新闻
const newsInterface = {
    // 埋点  记录数据
    recordingData: params => {
        /**
         * id           文章id
         * type         1 点赞 2 收藏 3 分享 4 阅读
         */
        return post(baseUrl + '/api-ma/news/article/update', params)
    },
    // 新闻首页的tab栏数据
    newsTabList: params => {
        return get(baseUrl + '/api-smc/ui-m-nl/v1/news/nms', params)
    },
    //新闻列表
    newsLists: params => {
        return get(baseUrl + '/api-smc/ui-m-nl/v1/index/nns', params)
    },
    //新闻列表类
    newsListse: params => {
        return get(baseUrl + '/api-smc/ui-m-nl/v1/news/nns', params)
    },
    //新闻详情
    newsDetail: params => {
        /**
         * id         新闻id
         * mobile     用户手机号
         */
        return get(baseUrl + `/api-smc/ui-m-nl/v1/news/nnd/${params}`)
    },
    // 推荐新闻
    newsRecommend: params => {
        return get(baseUrl + '/api-ma/pc/event/articleList', params)
    },
    // 新闻搜索
    newsSearch: params => {
        return get(baseUrl + '/api-ma/app/mls/articleNews', params)
    },
    // 新闻搜索关键字
    newsKeyWords: params => {
        return post(baseUrl + '/api-ma/keywords/list', params)
    },
    // 新闻评论列表
    newsCommentList: params => {
        /** 
         * articelId  新闻id
         * accountId  用户ID
         * pageNo     页码
         * pageSize
         */
        // return post('/api-ma/comment/news/list', params)
        return post(baseUrl + '/api-ma/pc/article/list', params)
    },
    // 新闻评论回复
    newsReply: params => {
        /**
         * mainCommentId   主评id
         * commentId       评论id
         * replyAuthor     回复人id
         * followAccountId 被评论人id
         * content         内容
         *
         * */
        return post(baseUrl + '/api-ma/comment/news/replyComment', params)
    },
    // 查看全部评论
    seeAllReply: params => {
        /**
         * commentId   主评id
         * accountId   用户id
         *
         * */
        return post(baseUrl + '/api-ma/comment/news/commentDetail', params)
    },
    // 给评论点赞
    getReplyLike: params => {
        /**
         * mainCommentId 主评id
         * commentId     评论id
         * accountId     被点赞用户id
         * authorId      点赞用户id
         * 
         * */
        return post(baseUrl + '/api-ma/comment/news/like', params)
    },
    // 新闻收藏
    getNewsCollect: params => {
        /**
         * mobile     手机号
         * articleId  文章id
         * status     0 取消收藏   1 添加收藏传
         * */
        return post(baseUrl + '/api-ma/app/mls/shoucang', params)
    },
}
// 赛事日历接口
const calendarInterface = {
    /** 
     * 获取数据
     * startDate 开始时间
     * endDate   结束时间
     * area      地区
     */
    getData: params => {
        return get(baseUrl + '/api-smc/ui-m-nl/v1/mh/calendar', params)
    },
    // 获取省份信息
    getProvince: params => {
        return get(baseUrl + '/api-ma/personal-center/province/list', params)
    },
}
// 意见反馈
const adviceInterface = {
    /**
     * 提交意见
     * problem    问题描述
     * files      图片文件(string)
     * type       类型(1: 功能建议, 2: 功能不可用， 3: 其他问题)
     * accountId  用户id
     */
    submitAdvice: params => {
        return post2(baseUrl + '/api-smc/ui-m/v1/feed-back/afb', params)
    },
    // 图片上传地址
    // uploadImage: baseUrl + '/app/wxa/uploadImage'
}
// 个人中心
const personalCenter = {
    /** 我的消息模块 */
    // 获取消息数量
    getNoticeQuantity: params => {
        /**
         * accountId   用户id
         */
        return get(baseUrl + '/api-ma/push/notify/index', params)
    },
    // 获取消息Tab列表
    getTabData: params => {
        /**
         * accountId   用户id
         * type        类型 1 赛事 2 装备 3 新闻 4 点赞 5 评论 6 点评 7 问大家 8 通知
         * pageNo     页码
         * pageSize   记录条数
         */
        // return get('/pc/other/notify-list', params)
        return get(baseUrl + '/api-smc/ui-m/v1/msg/gl-record', params)
    },
    // 获取消息列表
    getNoticeData: params => {
        /**
         * accountId   用户id
         * type        类型 1 赛事 2 装备 3 新闻 4 点赞 5 评论 6 点评 7 问大家 8 通知
         * pageNo     页码
         * pageSize   记录条数
         */
        // return get('/pc/other/notify-list', params)
        return get(baseUrl + '/api-smc/ui-m/v1/msg/record', params)
    },
    /** 读单条消息 */
    readSingleNotice: params => {
        /**
         * id         消息id
         * accountId  用户id
         * type       通知类型
         */
        return put(baseUrl + '/api-smc/ui-m/v1/msg/gl-red', params)
    },
    /** 一键全读消息 */
    readAllNotice: params => {
        /**
         * type       类型 1 赛事 2 装备 3 新闻 4 点赞 5 评论 6 点评 7 问大家 8 通知
         * accountId  用户id
         */
        return put(baseUrl + '/api-smc/ui-m/v1/msg/gl-red', params)
    },
    /** 我的关注模块 */
    getConcernData: params => {
        /** 
         * accountId  用户id
         * type       1 新闻收藏  2 赛事关注  6 点评收藏-提问收藏 
         * pageNo     页码
         * pageSize
         */
        return get(baseUrl + '/api-smc/ui-m/v1/follow/matches', params)
    },
    /** 我的收藏模块 */
    // 点评问大家收藏列表
    getCollectionData: params => {
        /** 
         * accountId 用户id
         * type      1 新闻收藏  2 赛事关注  6 点评收藏-提问收藏 
         * pageNo    页码
         * pageSize
         */
        return get(baseUrl + '/api-ma/pc/other/collection-list', params)
    },
    // 新闻收藏列表
    getNewsCollectList: params => {
        /** 
         * mobile  手机号
         */
        return get(baseUrl + '/api-ma/app/mls/shoucangList', params)
    },
    /** 报名人信息模块 */
    // 获取单个报名人信息
    getEnrollInfo: params => {
        /**
         * orderUserId
         * entryId      场次ID
         * mobile
         * outTradeNo   交易订单号
         */
        return post(baseUrl + '/api-ma/app/mls/order/getEnrollInfo', params)
    },
    // 获取用户列表
    getUserList: params => {
        /**
         * mobile  手机号
         * entryId 赛事类型的id
         */
        return get(baseUrl + '/api-ma/app/user/enrollUserList', params)
    },
    // 获取添加报名人基本表单信息
    getFormData: params => {
        return get(baseUrl + '/api-ma/app/user/getDynFrom', params)
    },
    // 获取动态表单
    getDynamicForms: params => {
        /**
         * entryId  赛事类型的id
         * 36e444b7b5684666842561e879f0d445
         */
        return get(baseUrl + `/api-smc/ui-m/v1/orders/eud/${params}`)
    },
    // 获取用户信息
    getUserInfo: params => {
        /**
         * personId 用户id
         * entryId  赛事类型的id
         */
        return get(baseUrl + '/api-ma/app/user/getEnrollUserById', params)
    },
    // 保存用户信息
    saveUserInfo: params => {
        /** 
         * 表单数据
         * mobile 手机号
         */
        return post(baseUrl + '/api-ma/app/user/saveEnrollUser', params)
    },
    // 设置默认报名人
    setDefaultUser: params => {
        /**
         * isDefault  设置传1，取消则不传
         * mobile
         * id         联系人id
         */
        return post(baseUrl + '/api-ma/app/user/setDefault', params)
    },
    // 删除信息
    deleteUser: params => {
        /**
         * personId 联系人id
         */
        return post(baseUrl + '/api-ma/app/user/deleteUser', params)
    },
    /** 报名记录模块 */
    // 获取报名记录列表
    getEnrollRecord: params => {
        /**
         * mobile 
         * pageNum
         */
        return get(baseUrl + '/api-smc/ui-m/v1/orders/ers', params)
    },
    getEnrollRecorde: params => {
        /**
         * mobile 
         * pageNum
         */
        return get(baseUrl + `/api-smc/ui-m/v1/orders/erd/${params}`)
    },
    // 获取订单详情
    getEnrollRecordDetail: params => {
        /**
         * outTradeNo 订单号
         * mobile     手机号
         * version    版本 version = new
         * entryId    赛事类型id
         * persions   报名人id的数组
         */
        return post(baseUrl + '/api-ma/pc/order/pcPaySuccess', params)
    },
}
// 上传图片地址
const uploadImage = 'https://gateway.qoqq.com/api-f/files/upload'
//装备
const equip = {
    //装备列表
    lists: query => {
        return get(equipUrl + '/app/goods_list.htm', query)
        // return get('https://www.qoqq.com/app/goods_list.htm', query)
    },
}
//登录注册
const basic = {
    //登录
    log: query => {
        return get(baseUrl + '/sys-login/exor', query)
    },
     //验证码登录
     logs: query => {
        return get(baseUrl + '/sys-login/sms', query)
    },
    //根据token获取用户信息
    suser: query => {
        return get(baseUrl + '/api-u/ui-m/v1/user/bgi', query)
    },
    //根据获取用户协议
    getXy: query => {
        return get(baseUrl + `/api-smc/ui-nl/v1/app-aga/${query.xy}`, query)
    },
    // 扫码登陆获取url
    sm: query => {
        return post(baseUrl + '/api-ma/pc/user/surl', query)
    },
    //注册
    reg: query => {
        return post(baseUrl + '/api-u/users-anon/marathon/reg', query)
    },
    //忘记密码
    forget: query => {
        return put(baseUrl + '/sys-pwd/reset', query)
        // phone    key   code   password
    },
    code2: query => {
        return get(baseUrl + '/api-nc/notify-nl/sms', query)
        // phone
    },
    //是否选过10天免登陆
    noLogin: query => {
        return post(baseUrl + '/api-ma/pc/user/mdr', query)
    },
    // 退出登录
    exit: query => {
        return post(baseUrl + '/api-ma/pc/user/outlogin', query)
    },

}
//扫码轮询
const smCode = query => {
    return post(baseUrl + '/api-ma/pc/user/suserinfo', query)
    // smtocken:
    //  FAIL(0, "提交失败"),
    // SUCC(1, "提交成功"),
    // NOT_BINDING(2, "账号未绑定"),

}
//默认搜索赛事关键词
const keyword = query => {
    return post(baseUrl + '/api-ma/keywords/list', query)
}
//赛事详情
const eventDetail = {
    // 赛事详情信息
    info: query => {
        return get(baseUrl + '/api-smc/ui-m-nl/v1/mh/amd', query)
    },
    // 赛事新闻
    news: query => {
        return get(baseUrl + '/api-smc/ui-m-nl/v1/mh/rns', query)
    },
    //赛事导航列表
    navs: query => {
        return get(baseUrl + `/api-smc/ui-m-nl/v1/mh/mqa/${query}`, )
    },
    //赛事点评
    // comment:query => {
    //     return get('comment/event/comment-list',query)
    // },
    comment:( query,param) => {
        return get(baseUrl + `/api-smc/ui-m-nl/v1/amc/mcs/${query}`,param )
    },
    //问大家
    // ask:query =>{
    //     return get('/comment/event/question-list',query)
    // },
    ask: query => {
        return get(baseUrl + '/api-ma/pc/event/question-list', query)
    },
    // 获取赛事类别
    type: query => {
        return get(baseUrl + `/api-smc/ui-m-nl/v1/entries/${query}`)
        // app/mls/getEventEntryList?mobile=手机号&&eventId=赛事id&&pageNo=1
    },
    jubao: query => {
        return post(baseUrl + '/api-ma/personal-center/report', query)
        // cqId:点评或提问的ID reason:举报理由 type： 1 赛事点评 2 赛事提问
    }
}
// 更新点击收藏分享点赞数量
const update = query => {
    return post(baseUrl + '/api-ma/news/article/update', query)
    // id:article id
    // type:  1 点赞  2 收藏  3 分享  4 阅读
}
//收藏，关注
const Like = query => {
    return put(baseUrl + '/api-smc/ui-m/v1/follow/set', query)
    // type:1 新闻收藏  2 赛事关注  3 点评收藏  4 提问收藏
    // id:新闻或赛事或点评或提问的ID
    // accountId:用户ID
}
//回复评论
const reply = {
    // 赛事点评的回复评论
    comment_reply: query => {
        return post(baseUrl + '/api-ma/comment/event/comment-reply', query)
    },
    // commentId:主评id
    // replyAuthor：回复者id
    // followAccountId：被回复者id
    // replyId：回复那条主评论的ID
    // type："1 主评  2 跟评"
    // content：回复内容
    // replyType：回复类型  1 对评论的回复   2 对点评的评论
    // 问大家
    ask_reply: query => {
        return post(baseUrl + '/api-ma/comment/event/question-reply', query)
    }
    // questionId:提问ID
    // replyAuthor:回复者的ID
    // followAccount:被回复者的ID
    // replyId:回复那条主评论的ID false
    // type:1 主评  2 跟评 false
    // content:回复内容
    // address:地址 false
    // replyType:1 对评论的回复   2 对点评的评论
}
// 点赞或取消点赞
const Zan = {
    // 点评点赞
    comment: query => {
        return put(baseUrl + '/api-smc/ui-m/v1/amc/mcp', query)
        // commentId/replyId：评论ID,如果是点赞回复的就传回复的ID
        // followAccountId:被点赞人的ID
        // authorId:点赞人的ID
    },
    // 问大家点赞
    ask: query => {
        return post(baseUrl + '/api-ma/comment/event/question-like', query)
        // questionId/replyId：评论ID,如果是点赞回复的就传回复的ID
        // followAccountId:被点赞人的ID
        // authorId:点赞人的ID
    }

}
// 订单 
const Order = {
    // 报名人信息
    users: query => {
        return get(baseUrl + '/api-ma/app/user/enrollUserList', query)
        // ?mobile=352464568636669952&&entryId=
    },
    // 判断用户是否可以正常报名
    Check: query => {
        return post(baseUrl + '/api-ma/app/mls/order/checkEnrollSelects', query)
    },
    // 附加项
    additions: query => {
        return get(baseUrl + '/api-ma/app/mls/getEventDyncList', query)
        // ?entryId=278496f79a7b429986a4a3aae470acbc
    },
    //下单
    getorder: query => {
        return post(baseUrl + '/api-ma/app/mls/order/enrolls', query)
        // {
        //     "mobile" : "15834711117",
        //     "sessionid" : "sessionid",
        //     "additionals" : "[{\"id\":\"0110a9f3d8ca48c5a7b9632f23fbe22d\",\"users\":[\"332919489584693248\",\"206800448584155136\"]},{\"id\":\"b203b34f785f4bc8bb6b8147d30fb46b\",\"users\":[\"332919489584693248\"]}]",
        //     "from" : "fromIos",
        //     "totalFee" : 0.059999998658895493,
        //     "entryId" : "36e444b7b5684666842561e879f0d445",
        //     "param" : "{\"222426199309180011\":\"206800448584155136\",\"457890\":\"332919489584693248\"}"
        //   }
    },
    // 获取订单信息
    getEnroll: query => {
        return get(baseUrl + '/api-ma/app/mls/order/getEnroll', query)
        // outTradeNo
        // mobile

    }
}
// 支付
// const Pay = {
//     //微信
//     wxPay:query => {
//         return post ('/api-ma/app/mls/order/pcwinxinPay',query)
//           // total_fee 支付金额
//         // body 支付信息
//         // outTradeNo 订单编号
//         // paopaoPay
//     },
//     // total_fee 支付金额
//     // body 支付信息
//     // accountId 支付人id
//     // outTradeNo 订单编号
//     // mobile 手机号
//     // payType 0 跑跑钱包   1 微信支付  2 支付宝支付  3 微信公众号支付  4 手机网页支付宝支付  5 邀请码支付  6 微信扫码支付
//     // payFrom   1 马拉松报名 2 马拉松小程序 3 跑跑APP 4 陪跑小程序
//     ZfbPay:query =>{
//         return post ('/api-ma/app/mls/order/alipay',query)
//     },
//     //付款成功 报名
//     apply:query => {
//         return post('/api-ma/app/mls/order/updateOrder',query)
//         // outTradeNo payWay 支付方式 0微信 1支付宝
//     }
// }
const Pay = {
    ZfbPay: query => {
        return post(baseUrl + '/api-ma/app/mls/order/unifiedPay', query)
    },
    //付款成功 报名
    apply: query => {
        return post(baseUrl + '/api-ma/app/mls/order/updateOrder', query)
        // outTradeNo payWay 支付方式 0微信 1支付宝
    },
    // payFrom   1 马拉松报名 2 马拉松小程序 3 跑跑APP 4 陪跑小程序
    aliPay: query => {
        return post(baseUrl + '/api-ma/app/mls/order/alipay', query)
    },
    // 轮询查询订单
    select: query => {
        return get(baseUrl + '/api-ma/pc/order/selectByOrder', query)
    }
}


export {
    banner,
    // Indexapi,
    event,
    ProblemFeedback,
    newsInterface,
    calendarInterface,
    adviceInterface,
    personalCenter,
    equip,
    basic,
    keyword,
    eventDetail,
    update,
    Like,
    reply,
    Zan,
    smCode,
    Order,
    Pay,
    uploadImage
    // newsComment
}