<template>
    <div class="loading"></div>
</template>

<script>
export default {
    name:'loading',
}
</script>

<style scoped>
.loading {
  position: fixed;
  left: 0;
  top: 55px;
  background: url('../../assets/public/loading.gif') center center no-repeat #fff;
  background-size: 8%;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}
</style>
