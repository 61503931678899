import Footer from './Footer/index'
import Header from './Header/index'
import Download from './downloadAlert/download.vue'
import pagination from './pagination/index'
import Login from './login/login'
import Register from './register/register'
import Resetpwd from './resetpwd/resetpwd'
import htmlPanel from './htmlPanel/index'
import Calendar from './calendar/index'
import infoDialog from './infoDialog/index'
import Loading from './Loading/index'
const install = function (Vue) {    
    if (install.installed) return
    Vue.component(Footer.name, Footer);
    Vue.component(Header.name, Header);
	Vue.component(Download.name,Download);
    Vue.component(Login.name,Login);
    Vue.component(Resetpwd.name,Resetpwd);
    Vue.component(Register.name,Register);
	Vue.component(pagination.name, pagination);
	Vue.component(htmlPanel.name, htmlPanel);
	Vue.component(Calendar.name, Calendar);
    Vue.component(infoDialog.name, infoDialog);
    Vue.component(Loading.name, Loading);
}
if (typeof window !== 'undefined' && window.Vue) {
    install(window.Vue)
}

export default {
    install
}