<template>
    <div class="alert" id="login">
        <div class="container_basic middle">
            <!-- 扫码登录 -->
            <!-- <template v-if="login_config">
                <header class="top">
                    <span class="header_tit">二维码登录</span>
                </header>
                <div class="cont">
                    <vue-qr :logoSrc="img" :text="url" :size="224" :margin="0" :dotScale = '1'></vue-qr>
                    <span class="code_info">请打开马拉松报名手机APP<br>赛事 > 扫一扫</span>
                    <span class="bot_info">没有账号？ <span @click="openRegister">注册</span></span>
                </div>
            </template>     -->
            <!-- 账号登录 -->
            <template>
                <header class="top2">
                    <span class="header_tit2" :class="{'header_tit':isLogins}" @click="isLogin(1)">{{tit}}</span>
                    <span class="header_tit2" :class="{'header_tit':!isLogins}" style="margin-left:50px" @click="isLogin(2)">{{tit2}}</span>
                </header>
                <div class="cont2">
                    <input type="text" class="tel_num" v-model="phoneNum" placeholder="请输入手机号">
                    <input type="password" class="pwd_num" v-model="pwdNum" placeholder="请输入密码" v-if="!isLogins">
<div class="testcode" v-else>
          <input
            type="text"
            class="test_num"
            placeholder="输入验证码"
            v-model="code"
          />
          <button class="test_txt" @click="fnVerify(0)" v-if="isGetcode">
            获取验证码
          </button>
          <button
            class="test_txt"
            v-else
            @click="fnVerify(1)"
            :disabled="isSend"
          >
            <span v-show="iscount">({{ count }}s)</span>重新发送
          </button>
        </div>
                    <div class="cont2_bot clearfix">
                        <span class="error f_left" v-show="isError"><span class="error_icon"></span>{{isError}}！</span>
                        <!-- <span class="forget f_right">
                            忘记了？
                            <span @click="openReset">找回密码</span>
                        </span> -->
                    </div>
                    <div class="noLogin clearfix" style="">
                        <div class="f_left">
                            <button class="check f_left" v-if="!isChecked" @click="chooseNologin"></button>
                            <button class="check check_act f_left" v-else @click="chooseNologin">✔</button>
                            <span class="f_left">我已阅读并同意<span style="display:inline-block;color:#d0021b;margin:0 4px" @click.stop="goXieyi('USER_AGREEMENT')">马拉松用户协议</span>和<span @click.stop="goXieyi('PRIVACY_POLICY')" style="display:inline-block;color:#d0021b;margin:0 4px">隐私政策</span>首次登录将自动注册</span>
                        </div>
                    </div>
                    <button class="login" @click.enter="login_submit">登录</button>
                    <!-- <span class="warn_txt">继续表示您同意<span @click.stop="goXieyi">马拉松用户协议</span></span> -->
                    <div class="noLogin clearfix">
                        <!-- <div class="f_left">
                            <button class="check f_left" v-if="isChecked" @click="chooseNologin"></button>
                            <button class="check check_act f_left" v-else @click="chooseNologin">✔</button>
                            <span class="f_left">十天免登录</span>
                        </div> -->
                        <span class="forget f_right">
                            忘记了？
                            <span @click="openReset">找回密码</span>
                        </span>
                    </div>
                </div>
                
            </template>
            <!-- <span class="code_img" v-if="login_config" @click="changeLogin"></span>
            <span class="code_img code_img2" v-else @click="changeLogin"></span> -->
            <div class="close_icon" @click="closeAlert"></div>
        </div>
        <!-- 人机验证 -->
    <Verify
      @success="fnVerifySuccess"
      :mode="'pop'"
      :captchaType="'blockPuzzle'"
      :imgSize="{ width: '330px', height: '155px' }"
      ref="verify"
    ></Verify>
    </div>
</template>

<script>
import {checkPhone} from '../../config/utils'
import {basic} from '../../config/request'
import vueQr from 'vue-qr'
import Verify from '../.././components/verifition/Verify.vue'
export default {
    name:'Login',
    components:{
        vueQr,
        Verify
    },
    inject:['reload'],
    props: {
        login_config:{
            type:Boolean
        },
        url:{
            type:String
        },
    },
    watch: {
        login_config(newval,oldval) {
            this.login_config = newval;
        },
        url(newval,oldval) {
            this.url = newval;
        }
    },
    data() {
        return {
            tit:'账号密码登录',//顶部标题
             tit2:'验证码登录',//顶部标题
            img:require('../../assets/public//favicon.png'),
            phoneNum:'',//手机号
            pwdNum:'',//密码
            isChecked:false,//默认不选择协议
            isError:'',//错误信息,
            isLogins:false,
            isGetcode: true, //默认显示获取验证码
      iscount: false, //一分钟倒计时是否显示
      isSend: false, //是否可以点击重新发送
            code:'',
            count:60,
            code_key:''
        }
    },
    methods: {
        isLogin(mode){
            this.isError=''
            this.code=''
            // this.pwdNum=''
            console.log(mode);
            if(mode==1){
    this.isLogins=false
     }else{
    this.isLogins=true
     }
        },
        coutTime() {
      this.iscount = true; //显示倒计时
      this.isSend = true; //倒计时之前不可点击重新发送
      this.count--;
      if (this.count == 0) {
        this.iscount = false; //隐藏倒计时
        this.isSend = false; //可以重新发送
        this.count = 60;
        return;
      }
      setTimeout(() => {
        this.coutTime();
      }, 1000);
    },
    // 人机验证
    fnVerify(num = 0) {
        console.log(checkPhone(this.phoneNum));
      // 校验手机号格式
      if (checkPhone(this.phoneNum)) {
          this.isError=''
        this.getCodeNum = num;
        this.$refs.verify.show();
    //   this.fnVerifySuccess();
      } else {
        this.isError = "请检查您的手机号";
      }
    },
     // 获取验证码
    fnVerifySuccess(params) {
         if (this.getCodeNum === 0) {
          this.isGetcode = false;
      } else {
        // 重新发送
        this.isSend = true;
        this.iscount = true;
      }
      this.tel_error = "";
        this.coutTime();
        console.log(params);
      basic
        .code2({
          phone: this.phoneNum,
          scope:'public',
          domain:'www.marathonbm.com',
          merchants_scope:'MC_SCOPE',
          verify_type:'captcha',
          captcha_verification:params.captchaVerification
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            console.log("获取验证码成功");
            this.code_key = res.data;
          }else{
               this.isError = res.message;
          }
        });
    },
        //是否选择是免登录
        chooseNologin() {
            this.isChecked = !this.isChecked;
        },
        // 切换登录方式
        changeLogin() {
            this.$emit('changeLogin',!this.login_config)
        },
        closeAlert() {
            this.isError = "";
            this.phoneNum = "";
            this.pwdNum = "";
            this.code=''
            this.$emit('closeLogin',false)
        },
        //打开注册弹框
        openRegister() {
            this.$emit('openRegister',true)
        },
        // 打开重置密码
        openReset() {
            this.$emit('openReset',true)
        },
        // 点击登录
        login_submit() {
            this.isError=''
            // 是否选择十天免登陆
            let mdr = this.isChecked ? 2 : 1;
            if(this.isChecked){
            if(!this.isLogins){
                console.log('账号和密码');
            if(this.phoneNum == "" && this.pwdNum != "") {
                this.isError = "账号不能为空";
            }else if(this.phoneNum != "" && this.pwdNum == "") {
                this.isError = "密码不能为空";
            }else if(this.phoneNum == "" && this.pwdNum == "") {
                this.isError = "账号和密码不能为空";
            }else {
                // let password = this.encryptString(this.pwdNum);
                basic.log({
                    param:this.phoneNum,
                    password:this.pwdNum,
                    remember:false,
                    // mdr:mdr,
                    registerFrom:4,
                    scope: 'MC_SCOPE'
                }).then(res => {
                    console.log(res);
                    if(res.code==200) {
                        // 获取token并存储
                        let access_token = res.data.access_token;
                        sessionStorage.setItem('access_token',access_token);
                        sessionStorage.setItem('loginTime',res.data.user.loginTime);
                        // 获取用户信息
                        this.$emit('setInfo',res.data.user)
                        this.reload();
                        this.isError = "";
                        this.closeAlert();
                    }else{
                        this.isError =res.message
                    }
                })
            } 
            }else{
                
                console.log(this.phoneNum == "" && this.code != "");
if(this.phoneNum == "" && this.code != "") {
    console.log('验证码');
                this.isError = "账号不能为空";
            }else if(this.phoneNum != "" && this.code == "") {
                this.isError = "验证码不能为空";
            }else if(this.phoneNum == "" && this.code == "") {
                this.isError = "账号和验证码不能为空";
            }else {
                let password = this.encryptString(this.code);
                basic.logs({
                    identification:this.code_key,
                    icode:this.code,
                    phone:this.phoneNum,
                    smsScope: 'public',
                    registerFrom:4,
                    scope: 'MC_SCOPE'
                }).then(res => {
                    console.log(res);
                    if(res.code==200) {
                        // 获取token并存储
                        let access_token = res.data.access_token;
                        sessionStorage.setItem('access_token',access_token);
                        sessionStorage.setItem('loginTime',res.data.user.loginTime);
                        // 获取用户信息
                        this.$emit('setInfo',res.data.user)
                        this.reload();
                        this.isError = "";
                        this.closeAlert();
                    }else{
                        this.isError =res.message
                    }
                })
            } 
            }
            }else{
                  this.$message.error('请阅读并勾选马拉松用户协议和隐私政策');
            }
        },
        // 查看协议
        goXieyi(xy) {
             this.$router.push({
                path:'/xieyi',
                query:{
                        xy:xy
                    }
            })
        }
    },
    mounted() {
        this.isError="";
    }
}
</script>

<style scoped>
@import '../../assets/alertPublic/public.css';
@import './style/login.css';
</style>
