import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import Index from '../views/Index/index'
import mIndex from '../mobileViews/mIndex/mIndex'
let routes;

// 判断不同设备设置不同路由
// if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
//   // 手机路由
//   routes = [
//     {
//       path: '/',
//       // name:'mIndex',
//       component: mIndex,
//       meta:{
//         title:'手机版首页',
//         keepAlive: true
//       }
//     },
//     {
//       path: '/event',
//       // name: 'mEvent',
//       component: () => import('../mobileViews/mEvent/mEvent.vue'),
//       meta: {
//         title: '手机版赛事'
//       }
//     },
//   ]
// } else {
  // pc路由
  routes = [
    {
      path: '/',
      name:'Index',
      component: Index,
      meta:{
        title:'马拉松报名'
      }
    },
    {
      path: '/event',
      name: 'event',
      component: () => import('../views/event/event.vue'),
      meta: {
        title: '马拉松报名-赛事'
      }
    },
    {
      path: '/eventDetail',
      name: 'eventDetail',
      component: () => import('../views/eventDetail/eventDetail.vue'),
      meta: {
        title: '马拉松报名-赛事'
      }
    },
    {
      path: '/activityDetail',
      name: 'activityDetail',
      component: () => import('../views/activityDetail/activityDetail.vue'),
      meta: {
        title: '马拉松报名-活动'
      }
    },
    {
      path: '/news',
      name: 'news',
      component: () => import('../views/news/newsIndex/news.vue'),
      meta: {
        title: '马拉松报名-新闻'
      }
    },
    {
      path: '/newsDetail',
      name: 'newsDetail',
      component: () => import('../views/news/newsDetail/newsDetail.vue'),
      meta: {
        title: '马拉松报名-新闻'
      }
    },
    {
      path: '/newsSearch',
      name: 'newsSearch',
      component: () => import('../views/news/newsSearch/newsSearch.vue'),
      meta: {
        title: '马拉松报名-新闻搜索'
      }
    },
    {
      path: '/equip',
      name: 'equip',
      component: () => import('../views/equip/equip.vue'),
      meta: {
        title: '马拉松报名-装备'
      }
    },
    {
      path: '/eventCalendar',
      name: 'eventCalendar',
      component: () => import('../views/eventCalendar/eventCalendar.vue'),
      meta: {
        title: '马拉松报名-赛事日历'
      }
    },
    {
      path: '/personalCenter',
      name: 'personalCenter',
      component: () => import('../views/personalCenter/personalCenter.vue'),
      meta: {
        title: '马拉松报名-个人中心',
        requireAuth:true
      }
    },
    {
      path: '/advice',
      name: 'advice',
      component: () => import('../views/advice/advice.vue'),
      meta: {
        title: '马拉松报名-建议反馈'
      }
    },
    {
      path:'/xieyi',
      name:'xieyi',
      component:() => import('../views/xieyi/xieyi.vue'),
      meta: {
        title: '马拉松报名-马拉松用户协议'
      }
    },
    {
      path:'/privacy',
      name:'privacy',
      component:() => import('../views/mls_ys/ys.vue'),
      meta: {
        title: '马拉松报名-马拉松隐私政策'
      }
    },
    {
      path: '/helpCenter',
      name: 'helpCenter',
      component: () => import('../views/helpCenter/helpCenter.vue'),
      meta: {
        title: '马拉松报名-帮助中心'
      }
    },
    {
      path: '/aboutUs',
      name: 'aboutUs',
      component: () => import('../views/aboutUs/aboutUs.vue'),
      meta: {
        title: '马拉松报名-关于我们'
      }
    },
    {
      path: '/order',
      name: 'order',
      component: () => import('../views/orderPay/orderPay.vue'),
      meta: {
        title: '马拉松报名-订单'
      }
    },
    {
      path: '/pay',
      name: 'pay',
      component: () => import('../views/pay/pay.vue'),
      meta: {
        title: '马拉松报名-支付'
      }
    },
    {
      path: '/error',
      name: 'noNetwork',
      component: () => import('../views/noNetwork/noNetwork.vue'),
      meta: {
        title: 'error'
      }
    }, 
    {
      path: '/app_view',
      name: 'app_view',
      component: () => import('../views/app_view/app_view.vue'),
      meta: {
        title: '马拉松报名-APP'
      }
    },
    {
      path: '/Data',
      name: 'Data',
      component: () => import('../views/Data.vue'),
      meta: {
        title: '数据看板'
      }
    }
  ]
// }
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

router.beforeEach((to,from, next) => {
  if (to.meta.title) { 
    document.title = to.meta.title;
  }
  // console.log(to, from)
  next();
})

export default router;