<template>
  <div class="alert">
    <div class="container_basic middle">
      <header class="top2">
        <span class="header_tit2">重置密码</span>
      </header>
      <div class="cont2">
        <input
          type="text"
          class="tel_num"
          placeholder="请输入手机号"
          v-model="telnum"
        />
        <span class="error_tel" v-show="tel_error"
          ><span class="error_icon"></span>{{ tel_error }}!</span
        >
        <div class="testcode">
          <input
            type="text"
            class="test_num"
            placeholder="输入验证码"
            v-model="code"
          />
          <button class="test_txt" @click="fnVerify(0)" v-if="isGetcode">
            获取验证码
          </button>
          <button
            class="test_txt"
            v-else
            @click="fnVerify(1)"
            :disabled="isSend"
          >
            <span v-show="iscount">({{ count }}s)</span>重新发送
          </button>
        </div>
        <span
          class="error error_test"
          v-show="code_err"
          style="marginbottom: 20px; margin-top: unset"
          ><span class="error_icon"></span>{{ code_err }}！</span
        >
        <input
          type="password"
          class="pwd_num"
          placeholder="请输入新密码"
          v-model="newPwd"
        />
        <input
          type="password"
          class="pwd_num again"
          placeholder="再次确认密码"
          v-model="ensurepwd"
        />
        <span class="error" v-show="pwdnum_error"
          ><span class="error_icon"></span>{{ pwdnum_error }}！</span
        >
        <div class="noLogin clearfix" style="">
                        <div class="f_left">
                            <button class="check f_left" v-if="!isChecked" @click="chooseNologin"></button>
                            <button class="check check_act f_left" v-else @click="chooseNologin">✔</button>
                            <span class="f_left">我已阅读并同意<span style="display:inline-block;color:#d0021b;margin:0 4px" @click.stop="goXieyi('USER_AGREEMENT')">马拉松用户协议</span>和<span @click.stop="goXieyi('PRIVACY_POLICY')" style="display:inline-block;color:#d0021b;margin:0 4px">隐私政策</span>首次登录将自动注册</span>
                        </div>
                    </div>
        <button class="login" @click="reset_submit">登录</button>
        <!-- <div class="noLogin clearfix">
          <button
            class="check f_left"
            v-if="isChecked"
            @click="chooseNologin"
          ></button>
          <button class="check check_act f_left" v-else @click="chooseNologin">
            ✔
          </button>
          <span class="f_left">十天免登录</span>
        </div> -->
        <!-- <div class="noLogin clearfix">
                    <span class="forget f_right">
                        算了，
                        <span>直接登录吧</span>
                    </span>
                </div> -->
      </div>
      <div class="close_icon" @click="closeAlert"></div>
    </div>
    <!-- 人机验证 -->
    <Verify
      @success="fnVerifySuccess"
      :mode="'pop'"
      :captchaType="'blockPuzzle'"
      :imgSize="{ width: '330px', height: '155px' }"
      ref="verify"
    ></Verify>
  </div>
</template>

<script>
import { settime, checkPhone, checkPwd } from "../../config/utils";
import { basic } from "../../config/request";
import Verify from "@/components/verifition/Verify";
export default {
  name: "Resetpwd",
  inject: ["reload"],
  components: {
    Verify,
  },
  data() {
    return {
      telnum: "", //手机号
      tel_error: "", //手机号报错信息
      code: "", //二维码
      code_err: "", //二维码错误信息
      newPwd: "", //新密码
      pwdnum_error: "", //密码错误信息
      ensurepwd: "", //确认密码
      count: 60,
      isGetcode: true, //默认显示获取验证码
      iscount: false, //一分钟倒计时是否显示
      isSend: false, //是否可以点击重新发送
      isChecked: false, //默认不选择十天免登陆
      code_key: "",
    };
  },
  methods: {
    // 人机验证成功
    fnVerifySuccess(params) {
      if (this.getCodeNum === 0) {
        this.isGetcode = false;
      } else {
        // 重新发送
        this.isSend = true;
        this.iscount = true;
      }
      this.tel_error = "";
      this.coutTime();
      this.getCode(params)
    },
    // 人机验证
    fnVerify(num = 0) {
      // 校验手机号格式
      if (checkPhone(this.telnum)) {
        this.getCodeNum = num;
        this.$refs.verify.show();

      // this.getCode();
      } else {
        this.tel_error = "请检查您的手机号";
      }
    },
    // 获取验证码
    getCode(params) {
      basic
        .code2({
          phone: this.telnum,
          scope:'public',
          domain:'www.marathonbm.com',
          merchants_scope:'MC_SCOPE',
          verify_type:'captcha',
          captcha_verification:params.captchaVerification
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            console.log("获取验证码成功");
            this.code_key = res.data;
          }else{
            this.isError = res.message;
          }
        });
    },
    // 一分钟倒计时
    coutTime() {
      this.iscount = true; //显示倒计时
      this.isSend = true; //倒计时之前不可点击重新发送
      this.count--;
      if (this.count == 0) {
        this.iscount = false; //隐藏倒计时
        this.isSend = false; //可以重新发送
        this.count = 60;
        return;
      }
      setTimeout(() => {
        this.coutTime();
      }, 1000);
    },
    //是否选择十天免登陆
    chooseNologin() {
      this.isChecked = !this.isChecked;
    },
    // 关闭弹窗
    closeAlert() {
      (this.telnum = ""), //手机号
        (this.tel_error = ""), //手机号报错信息
        (this.code = ""), //二维码
        (this.code_err = ""), //二维码错误信息
        (this.newPwd = ""), //新密码
        (this.pwdnum_error = ""), //密码错误信息
        (this.ensurepwd = ""), //确认密码
        this.$emit("closeReset", false);
    },
    // 查看协议
        goXieyi(xy) {
             this.$router.push({
                path:'/xieyi',
                query:{
                        xy:xy
                    }
            })
        },
    // 点击重置密码
    reset_submit() {
      this.tel_error = "";
       this.code_err=""
       this.pwdnum_error = "";
      if(this.isChecked){
         if (!checkPhone(this.telnum)) {
        this.tel_error = "请检查您的手机号";
      }else if (this.code==''){
        this.code_err="请检查您的验证码"
      }else if (this.newPwd !== this.ensurepwd) {
        this.pwdnum_error = "2次输入的密码不一致，请重新修改";
      } else if (!checkPwd(this.newPwd)) {
        this.pwdnum_error = "请输入包含数字字母的6~20位密码";
      } else {
        this.pwdnum_error = "";
        // let password = this.encryptString(this.newPwd);
        basic
          .forget({
            param: this.telnum,
            phone:this.telnum,
            newPassword: this.newPwd,
            icode: this.code,
            identification: this.code_key,
            scope: 'MC_SCOPE',
            smsScope: 'public'
          })
          .then((res) => {
            console.log(res)
            if (res.code == 200) {
              this.code_err = "";
              let mdr = this.isChecked ? 2 : 1;
              basic
                .log({
                  param: this.telnum,
                  password: this.newPwd,
                  remember:false,
                    // mdr:mdr,
                  registerFrom:4,
                  scope: 'MC_SCOPE'
                })
                .then((res2) => {
                  if (res2.code == 200) {
                    // 获取token并存储
                    let access_token = res2.data.access_token;
                    sessionStorage.setItem("access_token", access_token);
                    sessionStorage.setItem('loginTime',res2.data.user.loginTime);
                    // 获取用户信息
                    this.$emit("setInfo", res2.data.user);
                    this.closeAlert();
                    this.reload();
                  }
                });
            } else {
              this.code_err=res.message
            }
          });
      }
      }else{
        this.$message.error('请阅读并勾选马拉松用户协议和隐私政策');
      }
    },
  },
  mounted() {},
};
</script>
<style>
@import "../../assets/alertPublic/public.css";
@import "./style/resetpwd.css";
</style>
