<template>
    <div>手机首页</div>
</template>

<script>
export default {
    name:'mIndex'
}
</script>

<style>

</style>
