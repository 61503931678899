<template>
    <div class="calendar">
        <div class="time_select">
            <el-select v-model="selectYears" class="year_select" @change="selectYearChange">
                <el-option
                  v-for="y in years"
                  :key="y"
                  :label="y"
                  :value="y">
                </el-option>
            </el-select>
            <el-select v-model="selectMonth" class="month_select" @change="selectMonthChange">
                <el-option
                  v-for="(m, index) in months"
                  :key="m"
                  :label="m"
                  :value="index">
                </el-option>
            </el-select>
        </div>
        <table cellpadding="5" class="calendar_table">
            <thead class="calendar_thead">
                <tr class="calendar_thead_tr">
                    <td v-for="(week, index) in weeks" :key="index" class="week">{{week}}</td>
                </tr>
            </thead>
            <tbody class="calendar_tbody">
                <tr class="calendar_tbody_tr" v-for="(day,k1) in days" :key="k1" style="{'animation-delay',(k1*30)+'ms'}">
                    <td
                      v-for="(child,k2) in day"
                      :key="k2"
                      :class="{'selected':child.selected,'disabled':child.disabled}"
                      @click="select(k1, k2, $event)"
                    >
                        <div :class="['date_box', child.class]">
                            <span class="calendar_tbody_date">{{child.day}}</span>
                            <div class="text" v-if="lunar">{{child.lunar}}</div>
                        </div>
                        <div class="event" v-if="child.eventName!=undefined">{{child.eventName}}</div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import calendar from './calendar.js'
export default {
    name: 'm-calendar',
    props: {
        // 多选模式
        multi: {
            type: Boolean,
            default: false
        },
        // 范围模式
        range:{
            type: Boolean,
            default: false
        },
        // 默认日期
        value: {
            type: Array,
            default: function(){
                return []
            }
        },
        // 开始选择日期
        begin:  {
            type: Array,
            default: function(){
                return []
            }
        },
        // 结束选择日期
        end:  {
            type: Array,
            default: function(){
                return []
            }
        },

        // 是否小于10补零
        zero:{
            type: Boolean,
            default: false
        },
        // 屏蔽的日期
        disabled:{
            type: Array,
            default: function(){
                return []
            }
        },
        // 是否显示农历
        lunar: {
            type: Boolean,
            default: false
        },

        // 自定义星期名称
        weeks: {
            type: Array,
            default:function(){
                return ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
            }
        },
        // 自定义月份
        months:{
            type: Array,
            default:function(){
                return ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
            }
        },
        // 自定义事件
        events:  {
            type: Object,
            default: function(){
                return {}
            }
        }
    },
    data() {
        return {
            years:[],
            yearsShow:false,
            year: 0,
            month: 0,
            day: 0,
            selectYears: '',
            selectMonth: '',
            days: [],
            multiDays:[],
            today: [],
            festival:{
                lunar:{
                    "1-1":"春节",
                    "1-15":"元宵节",
                    // "2-2":"龙头节",
                    "5-5":"端午节",
                    "7-7":"七夕节",
                    // "7-15":"中元节",
                    "8-15":"中秋节",
                    "9-9":"重阳节",
                    // "10-1":"寒衣节",
                    // "10-15":"下元节",
                    "12-8":"腊八节",
                    // "12-23":"祭灶节",
                },
                gregorian:{
                    "1-1":"元旦",
                    "2-14":"情人节",
                    "3-8":"妇女节",
                    "3-12":"植树节",
                    "4-5":"清明节",
                    "5-1":"劳动节",
                    "5-4":"青年节",
                    "6-1":"儿童节",
                    "7-1":"建党节",
                    "8-1":"建军节",
                    "9-10":"教师节",
                    "10-1":"国庆节",
                    "12-24":"平安夜",
                    "12-25":"圣诞节",
                },
            },
            rangeBegin:[],
            rangeEnd:[],
            eventUlLeft: 10
        }
    },
    watch:{
        events(){
            this.render(this.year,this.month)
        },
        value(){
            this.init();
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init(){
            let now = new Date();
            this.year = now.getFullYear()
            this.month = now.getMonth()
            this.day = now.getDate()
            this.selectYears = this.year
            this.selectMonth = this.month + 1 + '月'
            for(let i=~~this.year-50;i<~~this.year+30;i++){
                this.years.push(i)
            }
            if (this.value.length > 0) {
                this.year = parseInt(this.value[0])
                this.month = parseInt(this.value[1]) - 1
                this.day = parseInt(this.value[2]) 
            }
            this.render(this.year, this.month)
        },
        // 渲染日期
        render(y, m) {
            let firstDayOfMonth = new Date(y, m, 1).getDay()         //当月第一天
            let lastDateOfMonth = new Date(y, m + 1, 0).getDate()    //当月最后一天
            let lastDayOfLastMonth = new Date(y, m, 0).getDate()     //最后一月的最后一天
            this.year = y
            let seletSplit = this.value
            let i, line = 0,temp = [],nextMonthPushDays = 1
            for (i = 1; i <= lastDateOfMonth; i++) {
                let day = new Date(y, m, i).getDay() //返回星期几（0～6）
                let k
                // 第一行
                if (day == 0) {
                    temp[line] = []
                } else if (i == 1) {
                    temp[line] = []
                    k = lastDayOfLastMonth - firstDayOfMonth + 1
                    for (let j = 0; j < firstDayOfMonth; j++) {
                        temp[line].push(Object.assign(
                            {day: k,disabled: true},
                            this.getLunarInfo(this.computedPrevYear(),this.computedPrevMonth(true),k),
                            this.getEvents(this.computedPrevYear(),this.computedPrevMonth(true),k),
                        ))
                        k++;
                    }
                }
       
                
                let chk = new Date()
                let chkY = chk.getFullYear()
                let chkM = chk.getMonth()
                // 匹配上次选中的日期
                if (parseInt(seletSplit[0]) == this.year && parseInt(seletSplit[1]) - 1 == this.month && parseInt(seletSplit[2]) == i) {
                    temp[line].push(Object.assign(
                        {day: i,selected: false,class: 'today_time'},
                        this.getLunarInfo(this.year,this.month+1,i),
                        this.getEvents(this.year,this.month+1,i),
                    ))
                    this.today = [line, temp[line].length - 1]
                }
                    // 没有默认值的时候显示选中今天日期
                else if (chkY == this.year && chkM == this.month && i == this.day && this.value == "") {
                    temp[line].push(Object.assign(
                        {day: i,selected: false,class: 'today_time'},
                        this.getLunarInfo(this.year,this.month+1,i),
                        this.getEvents(this.year,this.month+1,i),
                    ))
                    this.today = [line, temp[line].length - 1]
                }else{
                    // 普通日期
                    let options = Object.assign(
                        {day: i,selected:false},
                        this.getLunarInfo(this.year,this.month+1,i),
                        this.getEvents(this.year,this.month+1,i),
                    )
                    if (this.begin.length>0) {
                        let beginTime = Number(new Date(parseInt(this.begin[0]),parseInt(this.begin[1]) - 1,parseInt(this.begin[2])))
                        if (beginTime > Number(new Date(this.year, this.month, i))) options.disabled = true
                    }
                    if (this.end.length>0){
                        let endTime = Number(new Date(parseInt(this.end[0]),parseInt(this.end[1]) - 1,parseInt(this.end[2])))
                        if (endTime <  Number(new Date(this.year, this.month, i))) options.disabled = true
                    }
                    if (this.disabled.length>0){
                        if (this.disabled.filter(v => {return this.year === v[0] && this.month === v[1]-1 && i === v[2] }).length>0) {
                            options.disabled = true
                        }
                    }
                    temp[line].push(options)
                }
                // 到周六换行
                if (day == 6 && i < lastDateOfMonth) {
                    line++
                }else if (i == lastDateOfMonth) {
                    // line++
                    let k = 1
                    for (let d=day; d < 6; d++) {
                        temp[line].push(Object.assign(
                            {day: k,disabled: true},
                            this.getLunarInfo(this.computedNextYear(),this.computedNextMonth(true),k),
                            this.getEvents(this.computedNextYear(),this.computedNextMonth(true),k),
                        ))
                        k++
                    }
                    // 下个月除了补充的前几天开始的日期
                    nextMonthPushDays=k
                }
            } //end for
            // 补充第六行让视觉稳定
            if(line<=5 && nextMonthPushDays>0){
                for (let i = line+1; i<=5; i++) {
                    temp[i] = []
                    let start=nextMonthPushDays+(i-line-1)*7
                    for (let d=start; d <= start+6; d++) {
                        temp[i].push(Object.assign(
                            {day: d,disabled: true},
                            this.getLunarInfo(this.computedNextYear(),this.computedNextMonth(true),d),
                            this.getEvents(this.computedNextYear(),this.computedNextMonth(true),d),
                        ))
                    }  
                }
            }
            this.days = temp
        },
        computedPrevYear(){
            let value = this.year
            if(this.month - 1 < 0){
                value--
            }
            return value
        },
        computedPrevMonth(isString){
            let value=this.month
            if(this.month-1<0){
                value=11
            }else{
                value--
            }
            // 用于显示目的（一般月份是从0开始的）
            if(isString){
                return value+1
            }
            return value
        },
        computedNextYear(){
            let value=this.year
            if(this.month+1>11){
                value++
            }
            return value
        },
        computedNextMonth(isString){
            let value=this.month
            if(this.month+1>11){
                value=0
            }else{
                value++
            }
            // 用于显示目的（一般月份是从0开始的）
            if(isString){
                return value+1
            }
            return value
        },
        // 获取农历信息
        getLunarInfo(y,m,d){
            let lunarInfo=calendar.solar2lunar(y,m,d)
            let lunarValue=lunarInfo.IDayCn
            // console.log(lunarInfo)
            // let isLunarFestival=false
            // let isGregorianFestival=false
            if(this.festival.lunar[lunarInfo.lMonth+"-"+lunarInfo.lDay]!=undefined){
                lunarValue=this.festival.lunar[lunarInfo.lMonth+"-"+lunarInfo.lDay]
                // isLunarFestival=true
            }else if(this.festival.gregorian[m+"-"+d]!=undefined){
                lunarValue=this.festival.gregorian[m+"-"+d]
                // isGregorianFestival=true
            }
            return {
                lunar:lunarValue,
                // isLunarFestival:isLunarFestival,
                // isGregorianFestival:isGregorianFestival,
            }
        },
        // 获取自定义事件
        getEvents(y,m,d){
            if(Object.keys(this.events).length==0)return false;
            let time = y+"/"+m+"/"+d
            if(this.events[time] != undefined) {
                let eventName = this.events[time]
                // let eventList = this.events[time].eventList
                let data={}
                // 获取今天的时间戳
                let nowTime = new Date()
                let nowYear = nowTime.getFullYear()
                let nowMonth = nowTime.getMonth() + 1
                let nowDay = nowTime.getDate()
                let todayTimeFormat = nowYear+"/"+nowMonth+"/"+nowDay
                let eventTimeFormat = time
                let todayTime = new Date(todayTimeFormat).getTime()
                let eventTime = new Date(eventTimeFormat).getTime() // 获取赛事的时间戳
                if(eventName!=undefined){
                    data.eventName=eventName
                    // data.isChecked = false
                    // data.eventList = eventList
                    if(eventTime < todayTime) {
                        // 赛事时间已过去
                        data.class = 'overdue_time'
                    } else if(eventTime > todayTime) {
                        // 赛事时间还未到
                        data.class = 'future_time'
                    } else {
                        // 表示今天
                        data.class = 'today_time'
                    }
                }
                return JSON.parse(JSON.stringify(data))
            }
            
        },
        // 选择年份
        selectYearChange(year) {
            this.year = year
            this.render(this.year, this.month)
            this.$emit("selectTime", this.year, this.month + 1)
        },
        // 选择月份
        selectMonthChange(month) {
            this.month = month
            this.render(this.year, this.month)
            this.$emit("selectTime", this.year, this.month + 1)
        },
        // 选中日期
        select(k1, k2, e) {
            if(k2 > 3) {
                this.eventUlLeft = -(k2 + 0.2)
            } else if(k2 > 0) {
                this.eventUlLeft = -k2
            } else {
                this.eventUlLeft = 0.1
            }
            if (e != undefined) e.stopPropagation()
                // 日期范围
            // 取消上次选中
            if (this.today.length > 0) {
                this.days.forEach(v=>{
                    v.forEach(vv=>{
                        vv.selected= false
                    })
                })
            }
            // 设置当前选中天
            this.days[k1][k2].selected = true
            this.day = this.days[k1][k2].day
            this.today = [k1, k2]
            this.$emit('selectDay',[this.year,this.zero?this.zeroPad(this.month + 1):this.month + 1,this.zero?this.zeroPad(this.days[k1][k2].day):this.days[k1][k2].day])
        },
        // 返回今天
        setToday(){
            let now = new Date();
            this.year = now.getFullYear()
            this.month = now.getMonth()
            this.day = now.getDate()
            this.render(this.year,this.month)
            // 遍历当前日找到选中
            this.days.forEach(v => {
                let day=v.find(vv => {
                    return vv.day==this.day && !vv.disabled
                })
                if(day!=undefined ){
                  day.selected=true  
                }
                
            })
        },
        // 日期补零
        zeroPad(n){
            return String(n < 10 ? '0' + n : n)
        }
    }
}

</script>