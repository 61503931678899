<template>
    <div class="pagination_container">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :current-page="currentpage"
          @current-change="handleCurrentChange">
        </el-pagination>
    </div>
</template>
<script>
export default {
    name: 'm-pagination',
    props: {
        currentpage: {
            type: Number,
            default: 1
        },
        total: {
            type: Number,
            default: 10
        }
    },
    methods: {
        handleCurrentChange(page) {
            this.$emit("current-change", page)
        }
    },
}
</script>
<style>
    .pagination_container {
        margin-top: 53px;
    }
    .el-pagination {
        font-weight: normal;
        text-align: center;
    }
    .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li {
        width: 40px;
        height: 40px;
        background-color: #fff;
        font-size: 15px;
        color: #4A4A4A;
        line-height: 40px;
        border: 1px solid #CCCCCC;
        border-radius: 4px;
    }
    .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #DC1E1D;
        border: 0 none;
    }
    .el-pagination.is-background .el-pager li:not(.disabled):hover {
        color: #4A4A4A
    }
</style>
