<template>
  <div id="app">
      <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
import 'element-ui/lib/theme-chalk/display.css';
import {msgBox} from './config/utils'
export default {
  name: 'app',
  provide() {
    return {
      reload:this.reload
    }
  },
  data() {
    return {
      isRouterAlive:true,
    }
  },
  methods:{
    //页面刷新
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(()=> {
        this.isRouterAlive = true;
      })
    }
  },
  created() {
    localStorage.setItem('downloadUrl','https://www.marathonbm.com/down/marathon');
  }
}
</script>
<style>

</style>
