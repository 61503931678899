
export const showHtml = (str) => {
	var s = '';
	if (str.length === 0) {
		return '';
	}
s = str.replace(/&amp;/g, '&');
	s = s.replace(/&lt;/g, '<');
	s = s.replace(/&gt;/g, '>');
	s = s.replace(/&nbsp;/g, ' ');
	s = s.replace(/&#39;/g, '\'');
	s = s.replace(/&quot;/g, '\"');
	s = s.replace(/&ldquo;/g, '“')
	s = s.replace(/&rdquo;/g, '”')
	s = s.replace(/style='[^']+'/gi, "") //小程序展示要自己统一的样式，替换了行内样式
	s = s.replace(/(<!--(.|[\r\n])*?-->)/gi, "") //替换了注释的内容这能替掉大部分
	s = s.replace(/<xml[^>]*>(.|\n)*<\/xml>/gi, "") //替换了xml标签<xml></xml>word粘贴带出一堆标签包含在xml里干掉它
	s = s.replace(/undefined/g, "") //里面莫名有个undefined 干掉


// 正则表达式匹配所有的img标签
	let imgRegex = /<img[^>]*src="([^"]+)"[^>]*>/g;
	let imageRegex = /<image[^>]*src="([^"]+)"[^>]*>/g;
  
	// 使用正则表达式找到所有的图片链接
	let match = [];
let reg = new RegExp(imgRegex, "g");
// console.log('富文本图片---》',match)
	while ((match = reg.exec(s))) {
		// 修改图片链接
		let newSrc =DateFormatimg(match[1]);
		s = s.replace(match[1], newSrc);
	}
	return s;
}
export const DateFormatimg=(data)=> {
	// console.log(data)
	let dataImg = []
	let keyList = ['bgImage', 'image','coverImage','downloadUrl','images','cover','url','buyGoodsCover','combinedCover','commodityCover','coverPhoto','matchCover','poster','pictureUrl']//数组或对象需要拼接requestId的
	if (Array.isArray(data)) {
		let coverImage = []
		data.forEach((item) => {
			//1.数组包对象
			keyList.map((value, index) => {
				if (item[value]) {
					// console.log(item[value], '****************************数组包对象图片keyList----------')
					item[value] = getimg(item[value])
				}
			})
			//2.单纯数组
			if (typeof(item) === 'string') {
				// console.log(item, '****************************单纯数组图片keyList----------')
				coverImage.push(getimg(item))
				data = coverImage
			}
		})
	}
	//对象
	if (Object.prototype.toString.call(data)) {
		Object.keys(data).forEach(function(key) {
			keyList.map((value, index) => {
				if (key==value&&data[key]) {
					// console.log(key,value,data[key], '****************************对象图片keyList----------')
					data[key] = getimg(data[key])
				}
			})
		})
	}
	//字符
	if (typeof(data) === 'string') {
		console.log(data,'****************************字符串图片keyList----------')
		data=getimg(data)
		}
	dataImg = data
	console.log(dataImg)
	return dataImg
}
export const getimg=(dateStr)=>{
	let arr = ['982285707324166266', '982285927139250176', '982285927139250181', '982285927139250186',
		'982285927139250178'
	]
	let jiami = arr[Math.floor(Math.random() * arr.length)]
	let imgsrc = ''
	if (dateStr.indexOf('http') !== -1 && (dateStr.indexOf('file.marathonbm.com') !== -1||dateStr.indexOf('psq-img.hpaopao.com') !== -1||dateStr.indexOf('static.hpaopao.com') !== -1||dateStr.indexOf('marathon-web.hpaopao.com') !== -1||dateStr.indexOf('paopao-image.hpaopao.com') !== -1||dateStr.indexOf('marathon-image.hpaopao.com') !== -1)) {
		if (dateStr.indexOf('?') !== -1) {
			imgsrc = `${dateStr}&requestId=${jiami}`
		} else {
			imgsrc = `${dateStr}?requestId=${jiami}`
		}
	}else{
		imgsrc=dateStr
	}
	return imgsrc;
}