import axios from 'axios'
import qs from 'qs'
import { Loading } from 'element-ui';
import packageJson from '../../package.json';

const baseUrl = process.env.NODE_ENV === 'development' ? '/api' : 'https://gateway.marathonbm.com'
// const baseUrl = process.env.NODE_ENV === 'development' ? '/api' : 'https://gateway.ggpsq.com'
// const baseUrl = process.env.NODE_ENV === 'development' ? '/api' : 'https://test.ggpsq.com'
// const baseUrl = process.env.NODE_ENV === 'development' ? '/api' : 'http://192.168.2.165:61001'
const equipUrl = process.env.NODE_ENV === 'development' ? '/equip' : 'https://www.qoqq.com'


//axios.defaults.baseURL = baseUrl;
//设置超时时间
axios.defaults.timeout = 10000;
// axios.defaults.withCredentials = true;
// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
// axios.defaults.headers.post['Content-Type'] = 'application/json';
let loading;        //定义loading变量
//开始加载
function startLoading() {
    loading = Loading.service({
        lock: true,
        text: '加载数据中……',
        background: 'rgba(0, 0, 0, 0.45)',
        target: document.getElementById('#header_nav') //加载区域
    })
}
//关闭加载
function endLoading() {
    loading.close()
}

// 请求拦截器
axios.interceptors.request.use((config) => {
    if (window.sessionStorage.getItem("access_token")) { // 有token
        let token = window.sessionStorage.getItem("access_token");
        //将token放到请求头发送给服务器,将tokenkey放在请求头中
        config.headers.Authorization = 'Bearer ' + token
    }
    config.headers.platform = 'pc';
    config.headers.version = packageJson.version;
    config.headers.versionCode = packageJson.versionCode;
    
    return config;
}, function (error) {
    return Promise.reject(error);
});


// 封装get方法
function get(url, query) {
    return new Promise((resolve, reject) => {
        // startLoading();
        axios.get(url, {
            params: query,
            headers: {
                'domain-scope': 'www.marathonbm.com',
                'req-device-version':`H5[${navigator.userAgent}]|&#x53EF;&#x53D8;&#x7248;&#x672C;`,
            }
        }).then(res => {
            resolve(res.data);
            // endLoading();
        }).catch(err => {
            reject(err.data);
            // endLoading();
        })
    });

}
// 封装post方法
function post(url, query) {
    return new Promise((resolve, reject) => {
        // startLoading();
        axios.post(url, qs.stringify(query), {
            headers: {
                'domain-scope': 'www.marathonbm.com',
                'req-device-version':`H5[${navigator.userAgent}]|&#x53EF;&#x53D8;&#x7248;&#x672C;`,
            }
        }).then(res => {
            resolve(res.data);
            // endLoading();
        }).catch(err => {
            reject(err.data);
            // endLoading();
        })

    });
}
// 封装post方法
function post2(url, query) {
    return new Promise((resolve, reject) => {
        // startLoading();
        axios.post(url,query, {
            headers: {
                'content-type': 'application/json;charset=UTF-8',
                'domain-scope': 'www.marathonbm.com',
                'req-device-version':`H5[${navigator.userAgent}]|&#x53EF;&#x53D8;&#x7248;&#x672C;`,
            }
        }).then(res => {
            resolve(res.data);
            // endLoading();
        }).catch(err => {
            reject(err.data);
            // endLoading();
        })

    });
}
// 封装put方法
function put(url, query) {
    return new Promise((resolve, reject) => {
        // startLoading();
        axios.put(url, qs.stringify(query), {
            headers: {
                'domain-scope': 'www.marathonbm.com',
                'req-device-version':`H5[${navigator.userAgent}]|&#x53EF;&#x53D8;&#x7248;&#x672C;`,
            }
        }).then(res => {
            resolve(res.data);
            // endLoading();
        }).catch(err => {
            reject(err.data);
            // endLoading();
        })
    });

}
export {
    get,
    post,
    post2,
    put,
    baseUrl,
    equipUrl
}

