<template>
    <div class="header clearfix" id="public_head">
        <div class="header_main">
            <div class="header_nav" id="header_nav">
                <img src="../../assets/head_img/logo2.png" alt="" class="logo f_left" id="logo" @click="goIndex">
                <ul class="nav f_left">
                    <li :class="['f_left', {'active': isIndex}]"  @click="goIndex">首页
                    </li>
                    <li :class="['f_left', {'active': isEvent}]" @click="goEvent">赛事
                    </li>
                    <li :class="['f_left', {'active': isNews}]"  @click="goNews">新闻
                    </li>
                    <!-- <li :class="['f_left', {'active': isEquip}]"  @click="goEquip">装备
                    </li> -->
                    <li :class="['f_left', {'active': isApp}]" @mouseenter="appShow" @mouseleave="appHide" @click="goApp">APP
                        <!-- 下载二维码 -->
                        <div class="code" v-show="isappShow">
                            <vue-qr :logoSrc="img" :text="url" :size="111" :margin="0" :dotScale = '1'></vue-qr>
                            <span class="code_info">手机扫一扫下载APP</span>
                        </div>
                    </li>
                    <li :class="['f_left', {'active': isCalendar}]" @click="goCalendar">赛事日历
                    </li>
                </ul>
                <!-- <div class="search_box f_left" v-show="isNews">
                    <input type="text" placeholder="马拉松" value="" class="search_input" ref="searchInput" @focus="inputFocus" @blur="inputBlur">
                    <i class="search_icon"></i>
                </div> -->
                <div class="user_info f_right">
                    <template v-if="userInfo!=undefined">
                        <img v-if="userInfo.photo" :src="userInfo.photo|$DateFormatimg" alt=""
                            class="head_img f_left" 
                            @click="goUsercenter"
                            @mouseleave="closeInfo"
                            @mouseenter="showInfo">
                             <img v-else src="../../assets/icons/heard.png" alt=""
                            class="head_img f_left" 
                            @click="goUsercenter"
                            @mouseleave="closeInfo"
                            @mouseenter="showInfo">
                        <div class="info_detail" 
                            v-show="isInfoshow"
                            @mouseleave="closeInfo"
                            @mouseenter="showInfo">
                            <span class="name text_over">{{userInfo.nickName}}</span>
                            <span class="paopao_num text_over">ID:{{userInfo.runCode}}</span>
                            <div class="out">
                                <i class="out_icon f_left"></i>
                                <span class="out_txt f_left" @click="exit">退出</span>   
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="nickname f_left">
                            <span class="opera f_left" @click="Login">登录</span>
                            <!-- <span class="f_left line">|</span>
                            <span class="opera f_left" @click="Reg">注册</span> -->
                        </div>   
                    </template>
                   
                </div>
            </div>
        </div>
        <!-- 登录 -->
		<Login 
        v-show="isLoginshow"
        :url="codeurl"
        :login_config="login_config"
        @changeLogin="changeLogin"
        @openRegister="openRegister"
        @openReset="openReset"
        @setInfo="setInfo"
        @closeLogin="closeLogin"></Login>
        <!-- 注册 -->
        <Register 
        v-show="isRegistershow"
        @closeReg="closeReg"
        @openLogin="openLogin"
        @setInfo="setInfo"></Register>
        <!-- 重置密码 -->
        <Resetpwd 
        v-show="isResetpwdshow"
        @closeReset="closeReset"
        @setInfo="setInfo"></Resetpwd>
    </div>

</template>

<script>
import{basic,smCode} from '../../config/request.js'
import vueQr from 'vue-qr'
import linkedmeLink from '../../config/linkdeme';
import { setTimeout } from 'timers';
export default {
    name: 'Header',
    inject:['reload'],
    components: {
        vueQr
    },
    computed:{
        isLoginshow() {
            return this.$store.state.isLoginshow;
        }
    },
    watch:{
        userInfo(v) {
            // console.log(v)
            this.userInfo = v;
        }
    },
    data() {
        return {
            login_config:false,//默认显示扫码登录
            timer:null,//轮询计时器
            isRegistershow:false,//是否显示注册弹框
            isResetpwdshow:false,//是否显示重置密码弹框
            search_words:null,//搜索关键字
            isLogin:false,//是否登录
            isEvent:null,//是否为赛事页
            isIndex:true,//是否为首页
            isNews:null,//是否为新闻页
            isEquip:null,//是否为装备页
            isApp:null,//是否为app页
            isCalendar:null,//是否为赛事日历页
            userInfo:null,//用户信息
            isInfoshow:false,//鼠标移动到头像上显示信息
            isappShow:false,//鼠标移动到app上显示二维码
            codeurl:'',
            url:'',//app二维码地址
            img:require('../../assets/public/favicon.png'),
            mWidth:'',//浏览器宽度
        }
    },
    methods: {
        goIndex() {
            this.$router.push({
                path:'/',
            })
        },
         goEvent() {
            this.$router.push({
                path:'/event',
            })
        },
        goNews() {
            this.$router.push({
                path:'/news',
            })
        },
        goEquip() {
            this.$router.push({
                path:'/equip',
            })
        },
        goApp() {
            this.$router.push({
                path:'/app_view',
            }) 
        },
        goUsercenter() {
            this.$router.push({
                path:'/personalCenter',
            })
        },
        goCalendar() {
            this.$router.push({
                path:'/eventCalendar',
            })
        },
        //判断路由跳转页面
        isPath() {
            this.isIndex = this.$route.path == '/' ? true : false;
            this.isEvent = this.$route.path == '/event' ? true : false;
            this.isNews = this.$route.name == 'news' ? true : false;
            this.isEquip = this.$route.path == '/equip' ? true : false;
            this.isApp = this.$route.path == '/app_view' ? true : false;
            this.isCalendar = this.$route.path == '/eventCalendar' ? true : false;
        },
        //鼠标移动到app上显示下载app
        appShow() {
            this.isappShow = true;
        },
        appHide() {
            this.isappShow = false;
        },
        // 点击登录
        Login() {
            this.$store.commit('updateLogin',true)
            // 如果是扫码面轮询，账号面不轮询
            if(this.login_config) {
                this.getCodeurl()
            }else {
                if(this.timer) {
                    clearInterval(this.timer)
                }
            }
        },
        //扫码登录获取二维码
        getCodeurl() {
            basic.sm().then(res => {
                this.codeurl = res;
                let code_token = res.substr(res.indexOf('?')+1);
                this.getsmCode(code_token);
            })
        },
          // 轮询获取扫码信息
        getsmCode(token) {
            // 开始轮询获取信息
            this.timer = setInterval(()=>{
                smCode({
                    smtocken:token
                }).then(res => {
                    if(res.code==1||res.code==0) {
                        clearInterval(this.timer)
                        if(res.code ==1) {
                            sessionStorage.setItem('access_token',res.objectData);
                            this.takeUser(1);
                            this.$store.commit('updateLogin',false);
                        }
                    }
                })
            },1000)
        },
        // 关闭登录弹框
        closeLogin(v) {
            if(this.timer) {
                clearInterval(this.timer)
            }
            this.login_config = false
            this.$store.commit('updateLogin',v)
        },
        Reg() {
            this.isRegistershow = true;
        },
        // 切换登录方式
        changeLogin(val) { 
            // console.log(a)
            this.login_config = val;
            if(this.login_config) {
                this.getCodeurl()
            }else {
                if(this.timer) {
                    clearInterval(this.timer)
                }
            }
        },
        //关闭注册弹框
        closeReg(v) {
            this.isRegistershow = v;
        },
        //关闭重置密码弹框
        closeReset(v) {
            this.isResetpwdshow = v;
        },
        // 登录跳到注册
        openRegister(v) {
            this.isRegistershow = v;
            this.closeLogin(false);
        },
        //登录跳到重置密码
        openReset(v) {
            this.isResetpwdshow = v;
            this.closeLogin(false);
        },
        //注册跳到登录
        openLogin(v) {
            this.isRegistershow = false;
            this.Login();
        },
        //鼠标移动到头像上显示信息
        showInfo() {
            this.isInfoshow = true;
        },
        // 鼠标离开头像上隐藏信息
        closeInfo() {
            this.isInfoshow = false;
        },
        // 判断是否选过10天免登录
        noLogin() {
            basic.noLogin().then(res => {
                if(res.code == 1) {
                    sessionStorage.setItem('access_token',res.objectData);
                    this.takeUser(2);
                }
            })
        },
        // 根据token获取用户信息
        takeUser(type) {
            basic.suser().then(res => {
                console.log(res)
                if(res.code ==200) {
                    sessionStorage.setItem('userInfo',JSON.stringify(res.data));
                    this.userInfo = res.data;
                    // 1为扫码轮询的方式获取用户信息，扫完码更新
                    if(type === 1) {
                        this.reload();
                    }
                }
            })
        },
        // 点击退出登录
        exit() {
            this.open()
        },
        open() {
        const h = this.$createElement;
        this.$msgbox({
          title: '确认退出吗？',
          message: h('p', null, [
            h('span', { style: 'color: red' }, '退出后将不再接收任何赛事信息'),
            // h('i', { style: 'color: teal' }, 'VNode')
          ]),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
            //   instance.confirmButtonLoading = true;
            //   instance.confirmButtonText = '执行中...';
let routePath = this.$route.path
            // console.log(routePath.indexOf('personalCenter'))
            // basic.exit().then(res => {
                // if(res.code ==1) {
                    this.userInfo = null;
                    //清用户信息 清token
                    sessionStorage.removeItem('userInfo');
                    sessionStorage.removeItem('access_token');
                    sessionStorage.removeItem('loginTime')
                    this.reload();
                    if(routePath.indexOf('personalCenter') != -1 ) {
                        this.$router.push({
                            path: '/'
                        })
                    }
            //     }
            // })
              setTimeout(() => {
                done();
                setTimeout(() => {
                  instance.confirmButtonLoading = false;
                }, 300);
              }, 1000);
            } else {
              done();
            }
          }
        }).then(action => {
        //   this.$message({
        //     type: 'info',
        //     message: 'action: ' + action
        //   });
        });
      },
        // 存用户信息
        setInfo(v) {
            this.userInfo = v;
            sessionStorage.setItem('userInfo',JSON.stringify(v));
        },
    },
    created() {
        this.isPath();
        // 获取最新的用户信息
        this.takeUser(2);
    },
    mounted() {
        // 查看是否有用户信息
        if(sessionStorage.getItem('userInfo')) {
            this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
        }else {
            // 查看是否有十天免登录
            this.noLogin();
        }
        // 二维码打开app
        linkedmeLink({
            path: '', //跳转app的名字
        }).then(res => {
            this.url = res.url;
        })
    }
}
</script>

<style scoped>
@import './style/header.css';
</style>
