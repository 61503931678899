<template>
    <div class="Index_box">
         <!-- 加载动画 -->
        <transition name="fade">
            <loading v-if="isLoading"></loading>
        </transition>
        <Header :isLogin="isLoginshow"
        ref="Header"></Header>
        <!-- banner图 -->
        <el-carousel 
            :interval="3000" 
            arrow="always"
            indicator-position="none"
            :loop="true"
            trigger="click"  >
            <template v-if="banner.length">
                <el-carousel-item 
                v-for="(item,index) in banner" :key="index">
                    <!-- <div class="banner_item"
                    :style="{backgroundImage: 'url(' + `${item.image}` + ')', backgroundSize:'cover',backgroundRepeat: 'no-repeat',backgroundPosition: '50% 50%'}"
                    @click="jumpBanner(item)"
                    ></div> -->
                    <img class="banner_item"
                    :src="item.image"
                    style="objectFit:cover"
                    :alt="item.title"
                    @click="jumpBanner(item)"
                    >
                </el-carousel-item>  
            </template>
            <template v-else>
                <el-carousel-item>
                    <img src="" alt="" class="banner_item">
                </el-carousel-item>
            </template>
            
        </el-carousel>
        <!-- 内容 -->
        <div class="Index_cont">
            <div class="cont_item">
                <div class="item_top">
                    <span class="types_title">
                        最新赛事
                    </span>
                    <div class="types_info">
                        <span class="type_line"></span>
                        <span class="info_text">Latest events</span>
                        <span class="type_line"></span>
                    </div>
                </div>
                <ul class="item_lists clearfix">
                    <template v-if="eventlists">
                        <li class="list f_left" v-for="(item,index) in eventlists" :key="index" @click="goEventdetail(item.id)">
                            <img :src="item.images"  class="list_img" :alt="item.name">
                            <div class="list_info">
                                <h3 class="tit">{{item.title|dealTit}}</h3>
                                <span class="begin_time">竞赛时间：{{item.matchStartDate|dealTime}}</span>
                                <div class="end_time clearfix">
                                <div v-if="!item.isEnroll">
                                    {{item.label}}
                                </div>
                                    <div v-else>
                                     <template  v-if="item.status==-1">
                                        <span class="end_txt f_left">赛事已关闭</span>
                                    </template>
                                    <template  v-else-if="item.status==0">
                                        <span class="end_txt f_left">距报名开始：{{time[index]}}</span>
                                    </template>
                                    <template  v-else-if="item.status==1">
                                        <span class="end_txt f_left">距报名结束：{{time[index]}}</span>
                                    </template>
                                    <template  v-else-if="item.status==2">
                                        <span class="end_txt f_left">报名已结束</span>
                                    </template>
                                    <template  v-else-if="item.status==3">
                                        <span class="end_txt f_left">即将开赛：{{time[index]}}</span>
                                    </template>
                                    <template  v-else-if="item.status==4">
                                        <span class="end_txt f_left">据赛事结束：{{time[index]}}</span>
                                    </template>
                                    <template  v-else>
                                        <span class="end_txt f_left">赛事已完成</span>
                                    </template>
                                    </div>
                                    <button v-if="item.follow" class="followed f_right" @click.stop="followed(index,item.id,item)">已关注</button>
                                    <button v-else class="unfollowed f_right"  @click.stop="followed(index,item.id,item)">+关注</button>
                                </div>
                            </div>
                        </li>
                    </template>
                    <template v-else>
                        <li class="nolist">
                            <img src="../../assets/icons/index_not.png" alt="">
                            <span>当前没有符合您要求的赛事哦~</span>       
                        </li>
                    </template>       
                </ul>
                <div class="lookmore">
                    <a href="javascript:;" class="lookmore_txt" @click="goEvent">点击查看更多></a>
                </div>
            </div>
            <div class="cont_item cont_item2">
                <div class="item_top">
                    <span class="types_title">
                        跑跑新闻
                    </span>
                    <div class="types_info">
                        <span class="type_line"></span>
                        <span class="info_text">Running news</span>
                        <span class="type_line"></span>
                    </div>
                </div>
                <ul class="item_lists clearfix">
                    <template v-if="newlists.length">
                        <li class="list2 f_left" v-for="(item,index) in newlists" :key="index" @click="goNewdetail(item.id)">
                            <!-- 是否有图 -->
                            <template v-if="item.coverType!=='VIDEO'">
                                <img :src="item.coverImage" v-if="item.coverImage"  class="list_img" :alt="item.title" style="object-fit:cover">
                                <img src="../../assets/icons/icon_image_cloading@3x.png" v-else class="list_img" :alt="item.title" style="object-fit:cover">
                            </template>
                             <template v-else>
                                <!-- <img :src="item.coverImage" v-if="item.coverImage"  class="list_img" :alt="item.title"> -->
                                <video v-if="item.coverImage"  :src="item.coverImage" style="width: 100%;height: 185px;
  object-fit: scale-down;" controls ></video>
                                <img src="../../assets/icons/icon_image_cloading@3x.png" v-else class="list_img" :alt="item.title" mode="scaleToFill">
                            </template>
                            <!-- <template >
                                <img src=""  class="list_img">
                            </template> -->
                            <div class="list_info">
                                <span class="info_detail text_twoLine" v-html="item.title"></span>
                            </div>
                        </li>
                    </template>
                    <template v-else>
                        <li class="nolist">
                            <img src="../../assets/icons/index_not.png" alt="">
                            <span>当前没有符合您要求的新闻哦~</span>       
                        </li>
                    </template>
                </ul>
                <div class="lookmore">
                    <a href="javascript:;" class="lookmore_txt" @click="goNews">点击查看更多></a>
                </div>
            </div>
            <!-- <div class="cont_item">
                <div class="item_top">
                    <span class="types_title">
                        海尔斯跑鞋
                    </span>
                    <div class="types_info">
                        <span class="type_line"></span>
                        <span class="info_text">HEALTH running shoes</span>
                        <span class="type_line"></span>
                    </div>
                </div>
                <ul class="item_lists clearfix">
                    <template v-if="equiplists.length">
                        <li class="list3 f_left" v-for="(item,index) in equiplists" :key="index" @click="openDownload">
                            <img :src="item.goods_main_photo"  class="list3_img" :alt="item.goods_name">
                            <div class="list3_info">
                                <h3 class="tit text_twoLine">{{item.goods_name}}</h3>
                                <div class="score">
                                  
                                    <el-rate
                                    class="score_icon"
                                    v-model="item.goods_point"
                                    disabled
                                    disabled-void-color="#D5D5D5"
                                    >
                                    </el-rate>
                                    {{item.goods_point|dealscore}}分
                                </div>
                                <div class="price clearfix">
                                    <span class="price_info f_left">￥
                                        <span class="price_num">{{item.goods_current_price|dealscore}}</span>
                                    </span>
                                    <span class="pinkage f_right">包邮</span>
                                </div>
                            </div>
                        </li>
                    </template>
                    <template v-else>
                        <li class="nolist">暂无装备内容</li>
                    </template>  
                </ul>
                <div class="lookmore">
                    <a href="javascript:;" class="lookmore_txt" @click="goEquip">点击查看更多></a>
                </div>
            </div> -->
        </div>
        <!-- 侧边栏 -->
        <div class="aside">
            <ul class="aside_navs">
                <li class="aside_nav">
                    <router-link to="/helpCenter">
                        <i class="aside_icon help_icon"></i>
                        <span class="hint">帮助中心</span>
                    </router-link>
                </li>
                <li class="aside_nav">
                    <router-link to="/advice">
                        <i class="aside_icon advice_icon"></i>
                        <span class="hint">建议反馈</span>
                    </router-link>
                </li>
                <li class="aside_nav" @click="toPersonalCenter(1)">
                    <i class="aside_icon msg_icon"></i>
                    <span class="hint">我的消息</span>
                </li>
                <li class="aside_nav" @click="toPersonalCenter(2)">
                    <i class="aside_icon focus_icon"></i>
                    <span class="hint">我的关注</span>
                </li>
                <li class="aside_nav" @click="backTop">
                    <i class="aside_icon back_icon"></i>
                    <span class="hint">回顶部</span>
                </li>
            </ul>
        </div>
        <Footer :footerBgColor="'#FBFBFB'"></Footer>
        <!-- 弹框下载app -->
        <Download
        :isDownloadshow="isDownloadshow"
        :url="downloadUrl"
        @closeAlert="closeAlert"></Download>
    </div>
</template>

<script>
import {banner,Indexapi,event,newsInterface,equip,Like} from '../../config/request';
import {formatDuring} from '../../config/utils';
import vueQr from 'vue-qr';
import linkedmeLink from '../../config/linkdeme';
import axios from 'axios';
export default {
    components:{
        vueQr
    },
    filters: {
        //竞赛时间截取前十个字符年月日
        dealTime(v){
            var data = v.length>10 ? v.substr(0,10)+'' : v;
            var year = data.split('-')[0];
            var month = data.split('-')[1];
            var date = data.split('-')[2];
            return year+'年'+month+'月'+date+'日';
        },
        // 标题取15个字符
        dealTit(v) {
            return v.length>15 ? v.substr(0,15)+'...' : v;
        },
        // 若为2张图显示第1张
        dealimg(v) {
            return v.substr(0,v.indexOf('|'));
        },
        // 内容取40个字
        dealCont(v) {
            return v.length>40 ? v.substr(0,40)+'...' : v;
        },
        // 装备评分保留一位小数
        dealscore(v) {
            return Number(v).toFixed(1);
        },
        // 赛事图片处理
        dealEventimg(v) {
            if(v.indexOf('http') == 0) {
                return v;
            }else {
                return v.substr(v.indexOf('http'));
            }
        }
    },
    data() {
        return {
            str:'11111111111111111111111111111111111111111111111111',
            banner:[],
            time:[],//倒计时格式年月日
            isLoginshow:false,//弹出登录框
            eventlists:[],//赛事
            newlists:[],//新闻
            equiplists:[],//装备
            isfollow:false,//是否关注,
            follow_index:'',
            url:'',
            img:require('../../assets/public/favicon.png'),
            isDownloadshow:false,//下载app弹框
            downloadUrl:'',//下载app弹框二维码url
            timehandle:null,
            isLoading:true
        }
    },
    methods: {
        // 跳转到个人中心的  我的消息 或  我的关注
        toPersonalCenter(type) {
            let isLogin = JSON.parse(sessionStorage.getItem('userInfo')) ? true : false
            if(isLogin) { // 已登录
                let componentName = type == 1 ? 'myNotice' : 'myConcern'
                this.$router.push({
                    path: '/personalCenter',
                    query: {
                        componentName: componentName
                    }
                })
            } else { // 未登录
                // 弹出登录框
                this.$refs.Header.Login();
            }
            
        },
        goEvent() {
            this.$router.push({
                path:'/event',
            })
        },
        goNews() {
            this.$router.push({
                path:'/news',
            })
        },
        goEquip() {
            this.$router.push({
                path:'/equip',
            })
        },
        //返回顶部
        backTop() {
            document.documentElement.scrollTop = 0;
        },
        // 获取轮播图
        getBanner() {
            banner({
                // type:3
            }).then(res => {
                // console.log(res)
                this.isLoading = false;
                if(res.code == 200) {
                    res.data=this.$DateFormatimg(res.data)
                    this.banner = res.data;
                }
            })
        },
        //获取赛事
        getEvents() {
            let accountid;
            if(sessionStorage.getItem('userInfo')) {
                accountid = JSON.parse(sessionStorage.getItem('userInfo')).id;
            }else {
                accountid='';
            }
            event.recommends({
                // accountid:accountid
                size:6
            }).then(res => {
                console.log(res)
                if(res.code==200 && res.data) {
                    res.data=this.$DateFormatimg(res.data)
                    this.eventlists = res.data.slice(0,6);
                    console.log(this.eventlists)
                    res.data.map(item => {
							// item.matchStartDate = this.$u.timeFormat(item.matchStartDate,
							// 	'yyyy-mm-dd') //开赛时间 (返回的是中国标准时间) 转换成正常时间年-月-日 时:分:秒
							item.isEnroll = true;
							// console.log('map',item);
							var canlder = (item.state & 0XF0);
							var expire = (item.state & 0X04);
							var cancel = (item.state & 0X01);
							console.log(item.state);
							if (canlder == 16) {
								console.log('item.isEnroll = false;')
								item.isEnroll = false;
							}
							if (cancel == 1 || expire == 4) {
								if (cancel == 1) {
									item.label = "赛事已取消";
								} else {
									item.label = "赛事已延期";
								}
								item.isEnroll = false;
							}
						})
                    this.countdown();
                }
            })
        },
        //获取装备
        getEquip() {
            equip.lists().then(res => {
                if(res.code =='true') {
                    this.equiplists = res.goods_list.slice(0,10);
                }
            })
            // return new Promise((resolve, reject) => {
            //     axios({
            //         url: 'https://www.qoqq.com/app/goods_list.htm',
            //         method: 'get',
            //         data: {}
            //         }).then((res) => {
            //             if(res.code =='true') {
            //                 this.equiplists = res.goods_list.slice(0,10);
            //                 resolve(res.data);
            //             }
            //         })
            // });
        },
        //获取新闻
        getNews() {
            newsInterface.newsLists({
                rows:1,
                size:8
                // pageNo:1,
                // module:'xwzx'
            }).then(res => {
                console.log(res)
                if(res.code==200 && res.data.length) {
                    res.data=this.$DateFormatimg(res.data)
                    this.newlists=res.data
                    // res.data.map(item => {
					// 		item.publishTime = this.$u.timeFormat(item.publishTime,
					// 			'yyyy-mm-dd') //开赛时间 (返回的是中国标准时间) 转换成正常时间年-月-日 时:分:秒
					// 	})
						// this.listData = res.data;
					
						this.newlists.forEach(item => {
							// item.publishTime=this.dateFormat(item.publishTime) 
                            if(item.coverImage){
                                const index = item.coverImage.indexOf('|')
							if (index !== -1) {
								// console.log(item)
								item.coverImage = item.coverImage.split('|')
								if (item.coverType == "SINGLE_GRAPH") {
									item.coverImage = item.coverImage[1]
								}
							}
                            }
							

						})
                    this.newlists = this.newlists
                    console.log(this.newlists)
                    // let newsArr = []
                    // res.list.slice(0,8).forEach(item => {
                    //     // 图片和视频处理
                    //     let imageArr = []
                    //     let liClass = ""
                    //     let isVideo = !item.image.indexOf('mp4') || !item.image.indexOf('flash')
                    //     if(isVideo) { // 视频
                    //         imageArr = item.image
                    //         liClass = 'news_video'
                    //     } else { // 图片
                    //         item.image = item.image.split('|')
                    //         item.image.forEach(subItem => {
                    //             if(subItem != "" && subItem.indexOf("http") != -1) {
                    //                 imageArr.push(subItem)
                    //             }
                    //         })
                    //         // 新闻图片3张以下的都按一张图片的页面样式展示
                    //         if(imageArr.length < 3) {
                    //             imageArr = imageArr.slice(0, 1) 
                    //             liClass = "news_one_img"
                    //         } else {
                    //             imageArr = imageArr.slice(0, 3)
                    //             liClass = "news_more_img"
                    //         }
                    //     }
                    //     newsArr.push({
                    //         id: item.id,
                    //         hits: item.hits,
                    //         image: imageArr,
                    //         title: item.title,
                    //         isVideo: isVideo,
                    //         liClass: liClass
                    //     })
                    // })
                    // this.newlists = newsArr;
                    // console.log(this.newlists)
                }
            })
        },
        //点击轮播图跳转不同页面
        jumpBanner(item) {
            if(item.type == 1) {
                // 跳到指定赛事详情页
                this.$router.push({
                    path: '/eventDetail',
                    query: {
                        eventId:item.jumpPath
                    }
                })
            }else if (item.type == 3) {
                //跳到指定新闻详情
                this.$router.push({
                    path:'/newsDetail',
                    query:{
                        articleId:item.jumpPath
                    }
                })
            }else if (item.type == 2) {
            //     //跳到装备页
                this.$router.push({
                    path: '/equip',
                })
            }else if (item.type == 5) {
                //跳到指定网址
                window.location.href = item.jumpPath;
                // window.open(item.jumpParam, '_blank')
            }
        },
        //时间倒计时
        countdown() {
             window.clearTimeout(this.timehandle)
            this.timehandle = setTimeout(()=> {
                // console.log('==================');
                let timeCur = [];
                let array = this.eventlists;
                for (let i = 0; i < array.length; i++) {
                    var name = "";
                    if (array[i].status == 1) {
                        var curTime = new Date(array[i].currentTime).getTime()+1000;
                        this.$set(array[i], 'currentTime',curTime)
                        var times = Date.parse(new Date(array[i].enrollEndDate.replace(/-/g, "/")));//指定时间的毫秒数
                        var sub = times - curTime;
                        // console.log(sub);
                        if (0 < sub && sub < 1000) {
                            break;
                        }
                        name = formatDuring(sub);
                    } else if (array[i].status == 2) {
                        var curTime = new Date(array[i].currentTime).getTime()+1000;
                        this.$set(array[i], 'currentTime',curTime)
                        var times = Date.parse(new Date(array[i].endDate.replace(/-/g, "/")));//指定时间的毫秒数
                        var sub = times - curTime;
                        if (0 < sub && sub < 1000) {
                            break;
                        }
                        name = formatDuring(sub);
                    } else if (array[i].status == 2) {
                        var curTime = new Date(array[i].currentTime).getTime()+1000;
                        this.$set(array[i], 'currentTime',curTime)
                        var times = Date.parse(new Date(array[i].matchStartDate.replace(/-/g, "/")));//指定时间的毫秒数
                        var sub = times - curTime;
                        name = formatDuring(sub);
                        if (0 < sub && sub < 1000) {
                            break;
                        }
                    }
                    timeCur.push(name);
                }
                // this.$set(this.time, timeCur)
                
                this.time = timeCur;
                // console.log(this.time)
                this.countdown();
            }, 1000)
        },
        // 跳到指定赛事详情页
        goEventdetail(eventId) {
            this.$router.push({
                path:'/eventDetail',
                query:{
                    eventId:eventId
                }
            })
        },
        // 跳到指定新闻详情页
        goNewdetail(newsId) {
            this.$router.push({
                path:'/newsDetail',
                query:{
                    articleId:newsId
                }
            })
        },
        // 关注
        followed(index,id,item) {
            if(!sessionStorage.getItem('userInfo')) {
                // 弹出登录框
                this.$refs.Header.Login();
            }else {
                let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
                let accountId = userInfo.id
                Like({
                    type:'MATCH',
                    id:id,
                    // accountId:accountId
                }).then(res=> {
                    console.log(res)
                    this.getEvents()
                    // 关注成功
                    // if(res.code ==1 &&res.objectData ==2) {
                    //     item.iscollection = 1;
                    // }else if(res.code ==1 &&res.objectData ==1) {
                    //     //取关
                    //     item.iscollection = 0;
                    // }
                })
            }
        },
        // 点击装备列表提示下载app
        openDownload() {
            this.isDownloadshow = true;
            // 进入装备列表
            linkedmeLink({
            path: '', //跳转app的名字
            }).then(res => {
                console.log(res)
                this.downloadUrl = res.url;
            })                   
              
        },
        // 关闭下载app弹框
        closeAlert() {
            this.isDownloadshow = false;
        },
        // 弹出登录弹框
        isLogined() {
            this.$store.commit('updateLogin',true)
        },
    },
    mounted() {
        // 轮播图箭头进入样式
        $('.el-carousel__arrow').mouseenter(function() {
            this.style.backgroundColor = "rgba(0,0,0,0.4)"
        })
        $('.el-carousel__arrow').mouseleave(function() {
            this.style.backgroundColor = "transparent"
        })
        this.getBanner();
        this.getEvents();
        this.getNews();
        this.getEquip();
    },
    destroyed() {
        clearTimeout(this.timehandle);
    }

}
</script>

<style>
    @import '../Index/style/index.css'
</style>
