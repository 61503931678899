import Vue from 'vue';
import Vuex from 'vuex';
import {update,Like,smCode,basic} from '../config/request';
import { resolve } from 'url';
Vue.use(Vuex);
const bb = new Vue();

export default new Vuex.Store({
  state: {
    isLoginshow:false,//是否弹出登录弹框
    userInfo:null,//登录后的个人信息
    codeUrl:'',//扫码登录二维码
    code_token:'',//验证token
  },
  mutations: {
    // 关闭或打开弹框
    updateLogin(state,bool) {
      state.isLoginshow = bool;
    },
    // 存储用户信息
    // getUser(state,obj) {
    //   state.userInfo = obj;
    // }
  },
  actions: {
    // 更新点赞关注阅读等操作
    updateHandle({commit},query) {
      update(query).then(res => {
        console.log(res)
      })
    },
  },
  modules: {

  }
})
