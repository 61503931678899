<template>
  <div class="footer" :style="{ backgroundColor: footerBgColor }">
    <div class="footer_main clearfix">
      <div class="main_left f_left">
        <img
          src="../../assets/foot_img/logo2.png"
          alt=""
          class="footer_logo f_left"
        />
        <div class="footer_mainInfo f_left">
          <ul class="footer_navs clearfix">
            <li class="footer_nav f_left">
              <router-link to="/aboutUs">关于我们</router-link>
            </li>
            <li class="footer_nav f_left">
              <router-link to="/helpCenter">帮助中心</router-link>
            </li>
            <li class="footer_nav f_left">
              <router-link to="/advice">建议反馈</router-link>
            </li>
            <li class="footer_nav f_left">
              <a href="https://weibo.com/5558637757/profile?topnav=1&wvr=6"
                >关注微博</a
              >
            </li>
          </ul>
          <div class="footer_intro clearfix">
            <span class="intro_txt f_left"
              >AIMS国际马拉松公路跑协会官方合作伙伴，AIMS官方指定平台</span
            >
            <img
              class="intro_logo f_left"
              src="../../assets/foot_img/logo3.png"
              alt=""
            />
          </div>
          <span class="footer_explain">
            Copyright 2015-{{ NowYear }} PAOPAO NETWORK TECHNOLOGY 跑跑网
            保留所有权利
            <a href="https://beian.miit.gov.cn" target="_blank"
              >浙ICP备15028232号-2</a
            >
          </span>
          <span class="contact_info">
            资讯电话：0571-87758152
            <span class="email_info">联系邮箱：bd@hpaopao.com </span>
          </span>
        </div>
      </div>
      <div class="main_right f_right">
        <vue-qr
          :logoSrc="img"
          :text="url"
          :size="111"
          :margin="0"
          :dotScale="1"
          class="code"
        ></vue-qr>
        <span class="code_info">扫码关注公众号</span>
      </div>
    </div>
  </div>
</template>

<script>
import vueQr from "vue-qr";
export default {
  name: "Footer",
  components: {
    vueQr,
  },
  props: {
    footerBgColor: {
      type: String,
      default: "#fff",
    },
  },
  data() {
    return {
      url:
        "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzI4NTEzOTIzMw==&scene=126&bizpsid=1552296338&sessionid=1552296338&subscene=0#wechat_redirect",
      img: require("../../assets/public/favicon.png"),
      NowYear: "",
    };
  },
  methods: {
    getYear() {
      var date = new Date();
      var y = date.getFullYear();
      this.NowYear = y;
    },
  },
  created() {
    this.getYear();
  },
};
</script>

<style>
@import "./style/footer.css";
</style>
